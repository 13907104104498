export const CloseIcon = ({className}: {className?: string}) => (
	<svg
		width="8"
		height="8"
		viewBox="0 0 8 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<g>
			<path
				id="Icon"
				d="M7 1L1 7M1 1L7 7L1 1Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
)
