import {Dialog, DialogPanel, DialogTitle, Transition, TransitionChild} from '@headlessui/react'
import {Fragment, type PropsWithChildren, useRef, useState} from 'react'
import {twMerge} from 'tailwind-merge'
import {InfoIcon} from '~/assets/InfoIcon.tsx'
import {useReferenceData} from '~/services/api.ts'

interface InfoButtonAndModalProps {
	heading: string
	className?: string
	iconClassName?: string
}

export const InfoButtonAndModal = ({heading, className, children, iconClassName}: PropsWithChildren<InfoButtonAndModalProps>) => {
	const okButtonRef = useRef(null)
	const [open, setOpen] = useState(false)

	return (
		<>
			<button
				type="button"
				className={twMerge('rounded-full bg-light-grey-25 p-[3px] dark:bg-navy-75', className)}
				onClick={() => {
					setOpen(true)
				}}
			>
				<InfoIcon
					className={twMerge('h-[15px] light:text-dark-grey-100', iconClassName)}
					aria-label="info"
				/>
			</button>
			<Transition
				show={open}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={okButtonRef}
					onClose={() => {
						setOpen(false)
					}}
				>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative m-8 w-full max-w-[512px] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all dark:bg-navy-100">
									<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
										<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-light-grey-25 sm:mx-0 dark:bg-navy-75">
											<InfoIcon
												className="h-[24px] light:text-dark-grey-100"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<DialogTitle
												as="h3"
												className="text-lg font-[600] light:text-dark-grey-100"
											>
												{heading}
											</DialogTitle>
											<div className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">{children}</div>
										</div>
									</div>
									<div className="bg-light-grey-25 px-[24px] py-[12px] text-sm font-[500] sm:flex sm:flex-row-reverse dark:bg-navy-75">
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-[6px] bg-light-blue-100 px-3 py-2 hover:bg-light-blue-75 active:bg-light-blue-50 sm:mt-0 sm:w-auto dark:bg-white dark:text-navy-100 dark:hover:bg-light-grey-25 dark:active:bg-light-grey-50"
											onClick={() => {
												setOpen(false)
											}}
											ref={okButtonRef}
										>
											Okay
										</button>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export function InfoModalLlm() {
	return (
		<InfoButtonAndModal heading="Large language model (LLM)">
			<div className="flex flex-col gap-y-[1rem]">
				<p>GPT-3.5 Turbo 16k and GPT-4 represent two different iterations of language models developed by OpenAI.</p>
				<p>GPT-3.5 Turbo 16k is a refinement of the GPT-3 model, it is essentially an enhanced version of GPT-3, so its understanding of complex or specialised topics may still be limited.</p>
				<p>
					GPT-4 is the next evolution in OpenAI's language models. It is expected to have a significantly improved understanding of both general and specialised topics. GPT-4 builds upon the
					advancements of its predecessors and is designed to provide more accurate and contextually relevant responses.
				</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalTemperature() {
	return (
		<InfoButtonAndModal heading="Temperature">
			<div className="flex flex-col gap-y-[1rem]">
				<p>Temperature influences the randomness and creativity of responses.</p>
				<p>A higher value generates more diverse and imaginative answers. A lower value generates more focused and deterministic responses, ideal for precision and consistency.</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalRagRetrievalCount() {
	return (
		<InfoButtonAndModal heading="RAG retrieval count">
			<div className="flex flex-col gap-y-[1rem]">
				<p>
					RAG retrieval count refers to the number of responses generated by the system when a query is made. A higher count typically yields a wider range of responses, providing more options for
					selecting the most relevant answer. However, increasing this count can also introduce more variability in the responses.
				</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalK() {
	return (
		<InfoButtonAndModal heading="k">
			<div className="flex flex-col gap-y-[1rem]">
				<p>'k' signifies the parameter controlling the depth of relevance for retrieved information.</p>
				<p>
					By adjusting 'k,' you can determine how many top-ranked items the system considers when generating responses. A higher 'k' value results in more extensive content, while a lower value
					prioritises the most relevant responses.
				</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalEmbedding() {
	return (
		<InfoButtonAndModal heading="Embedding">
			<div className="flex flex-col gap-y-[1rem]">
				<p>Embedding refers to the indexing process to ensure chunks of your data set are semantically findable.</p>
				<p>
					Embeddings are numeric vector representations of the meaning of your text in 100s of 'dimensions'. They carry the meaning of your text, enabling the system to find search hits to your
					queries, even if your text is expressed in entirely different phrasings or even if in a foreign language.
				</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalChunkSize() {
	return (
		<InfoButtonAndModal heading="Chunk size">
			<div className="flex flex-col gap-y-[1rem]">
				<p>Chunk size serves as a parameter that determines the size of data segments processed during vector creation for data sets.</p>
				<p>By specifying a chunk size, you provide instructions to the system regarding how data should be subdivided and analysed.</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalChunkType() {
	return (
		<InfoButtonAndModal heading="Chunk type">
			<div className="flex flex-col gap-y-[1rem]">
				<p>
					<strong>Document:</strong> The complete document undergoes vectorisation and is stored without being fragmented into smaller units. Please be aware that the file's maximum size for
					vectorisation should not exceed 8192 tokens; otherwise, an error will occur.
				</p>
				<p>
					<strong>Token:</strong> If the document surpasses the chunk size determined by token sizing, it is segmented accordingly. You can specify this chunk size using the dropdown value below.
				</p>
			</div>
		</InfoButtonAndModal>
	)
}

export function InfoModalUploadFiles() {
	const {data: referenceData} = useReferenceData()
	return (
		<InfoButtonAndModal
			heading="Upload files"
			className="bg-tranparent foucs:outline-white forcus: p-[2px] light:hover:bg-light-grey-25"
			iconClassName="size-[16px]"
		>
			<div className="flex flex-col gap-y-[1rem]">
				<p>Upload files directly into your thread and chat with them.</p>
				<p>
					<strong>File types:</strong>
					{'  '}
					{`${referenceData?.llm_features.file_upload.convert_file_types.replace(/,/g, ', ')}, ${referenceData?.llm_features.file_upload.text_file_types.replace(/,/g, ', ')}`}
				</p>
				<p>
					<strong>Max accumulative file size:</strong> 5MB
				</p>
			</div>
		</InfoButtonAndModal>
	)
}
