import {Draw} from '~/components/Draw.tsx'
import {type DataSet} from '~/model.ts'
import {formatDate} from '~/util.ts'

interface Props {
	dataSet?: DataSet
	onDone: () => void
}

export const GlobalDataSetInfo = ({dataSet, onDone}: Props) => {
	return (
		<Draw
			open={dataSet != null}
			onDone={onDone}
		>
			{dataSet != null && (
				<div className="overflow-y-auto px-[120px] py-[24px] text-sm">
					<div className="max-w-content">
						<h2 className="font-display text-2xl">Global data set information</h2>
					</div>

					<div className="mt-[48px] text-sm font-[600] light:text-dark-grey-100">Name</div>
					<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">{dataSet.name}</p>

					<div className="mt-[24px] text-sm font-[600] light:text-dark-grey-100">Description</div>
					{dataSet.description.length > 0 ? (
						<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">{dataSet.description}</p>
					) : (
						<p className="mt-[8px] text-dark-grey-25 dark:text-gray-500">(No description provided)</p>
					)}

					<div className="mt-[24px] flex w-full max-w-content-sm gap-[24px] rounded-[8px] border p-[24px] text-sm">
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">Last updated</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{formatDate(dataSet.modified_datetime)}</div>
						</div>
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">Chunk size</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{dataSet.vector_configuration.chunk_size}</div>
						</div>
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">RAG retrieval count</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{dataSet.query_configuration.size}</div>
						</div>
					</div>
				</div>
			)}
		</Draw>
	)
}
