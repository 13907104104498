import {useEffect, useState} from 'react'
import {useAuth} from 'react-oidc-context'

export function LoginCallback() {
	const auth = useAuth()
	const [hasTriedSignin, setHasTriedSignin] = useState(false)

	// Automatically sign-in
	useEffect(() => {
		if (auth.isAuthenticated) {
			const redirectAfterLogin = sessionStorage.getItem('redirect-after-login')
			sessionStorage.removeItem('redirect-after-login')
			window.location.replace(redirectAfterLogin ?? '/securechat')
		}

		console.log(auth.isAuthenticated, auth.activeNavigator)
		if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
			void auth.signinRedirect()
			setHasTriedSignin(true)
		}
	}, [auth, auth.isAuthenticated, hasTriedSignin])
	return null
}
