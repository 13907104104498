import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {SparkLogo} from '~/assets/SparkLogo.tsx'
import {UomLogo} from '~/assets/UomLogo.tsx'

const downForMaintenanceUntil = import.meta.env.VITE_DOWN_FOR_MAINTENANCE_UNTIL

export const MaintenancePage = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.ctrlKey && event.key.toLowerCase() === 'q') {
				localStorage.setItem('bypassMaintenance', Date.now().toString())
				navigate('/securechat')
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<div className="flex h-full flex-col items-center justify-center bg-navy-100 p-[24px]">
			<div className="flex flex-wrap items-center justify-center gap-[20px]">
				<UomLogo className="h-[90px]" />
				<SparkLogo className="mt-[3px] h-[78px]" />
			</div>
			<div className="mt-[24px] text-center font-display text-[70px] text-white">Down for Maintenance</div>
			<div className="mt-[12px] max-w-[1080px] text-center text-[38px] font-[300] text-gray-200">
				Spark AI is down for maintenance, we expect to have it back up by {downForMaintenanceUntil}. If you have any questions, please contact us on Teams in the 'Spark AI User Community'.
			</div>
		</div>
	)
}
