import {useParams} from 'react-router-dom'
import {InfoCircleIcon} from '~/assets/InfoCircleIcon.tsx'
import {Draw} from '~/components/Draw.tsx'
import {LoadingAnimationCentered} from '~/components/LoadingAnimation.tsx'
import {type UIMessage} from '~/model.ts'
import {useMessage} from '~/services/api.ts'
import {isValidHttpUrl} from '~/util.ts'

interface Props {
	message?: UIMessage
	onDone: () => void
}

export const MessageRagSources = ({message, onDone}: Props) => {
	const {projectId, threadId} = useParams()

	const hasSources = message?.sources != null && message.sources.length > 0

	const {data: messageDetails, isLoading} = useMessage(projectId, threadId, hasSources ? message.id : undefined)

	const max_score = messageDetails?.retrievalResults.reduce((max, result) => Math.max(max, result.score), 0)

	return (
		<Draw
			open={message != null}
			onCancel={onDone}
			onApply={onDone}
			applyLabel="Close"
		>
			<div className="overflow-y-auto">
				<div className="p-[24px] text-sm md:px-[120px]">
					<h2 className="font-display text-2xl">RAG sources</h2>
					<p className="mt-[8px] max-w-content text-[18px] light:text-dark-grey-100">
						Retrieval augmented generation (RAG) sources show you which chunks of your data set were referenced by the LLM to give the response.
					</p>

					{isLoading ? (
						<LoadingAnimationCentered />
					) : (
						<>
							<div className="mt-[24px] grid w-full max-w-content-lg grid-cols-1 gap-[24px] rounded-[8px] border p-[24px] min-[848px]:grid-cols-2 min-[1112px]:grid-cols-3">
								<div>
									<div className="pb-[4px] font-[500] dark:text-white">Thread ID</div>
									<div className="text-dark-grey-100 dark:text-light-grey-50">{threadId}</div>
								</div>
								<div>
									<div className="pb-[4px] font-[500] dark:text-white">Response ID</div>
									<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.id}</div>
								</div>
								<div>
									<div className="pb-[4px] font-[500] dark:text-white">Max Relevance Score</div>
									<div className="text-dark-grey-100 dark:text-light-grey-50">{max_score}</div>
								</div>
							</div>

							{messageDetails != null && (
								<div className="mt-[24px] flex max-w-content flex-col gap-y-[24px]">
									<div>
										{messageDetails.retrievalResults.length} chunk{messageDetails.retrievalResults.length > 1 && 's'}
									</div>
									{messageDetails.retrievalResults.map((result) => (
										<div
											key={result.location.s3Location.uri}
											className="flex flex-col gap-y-[12px]"
										>
											<div className="break-all text-base font-[600]">{result.location.s3Location.uri.split('/').pop()}</div>
											<div className="mt-[8px] flex items-start">
												<div className="text-semibold shrink-0 pr-[8px]">Source:</div>
												{isValidHttpUrl(result.location.s3Location.uri) ? (
													<a
														href={result.location.s3Location.uri}
														className="break-all underline"
													>
														{result.location.s3Location.uri}
													</a>
												) : (
													<div className="break-all">{result.location.s3Location.uri}</div>
												)}
											</div>
											<div className="mt-[8px] max-w-max flex-shrink-0 rounded-[4px] bg-light-blue-100 px-[10px] py-[2px] text-xs font-[500]">{`Relevance score: ${result.score}`}</div>
											<div className="mt-[8px] flex max-w-max items-start gap-x-[4px] rounded-[4px] bg-yellow-100 px-[10px] py-[2px] text-xs font-[500]">
												<div className="flex-shrink-0">URI:</div>
												<div className="grow break-all">{result.location.s3Location.uri}</div>
											</div>
											<div className="mt-[8px]">{result.content.text}</div>
										</div>
									))}
								</div>
							)}

							{!hasSources && (
								<div className="mt-[24px] flex max-w-content flex-col gap-y-[24px]">
									<div>0 chunks</div>
									<div className="flex items-center gap-x-[12px] rounded-r-[6px] border-l-[4px] border-light-blue-100 bg-light-blue-25 p-[16px] text-dark-blue-100 dark:bg-navy-100 dark:text-light-blue-100">
										<InfoCircleIcon className="h-[20px] shrink-0 light:text-navy-100" />
										<div>The LLM didn't reference any RAG sources for this response.</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</Draw>
	)
}
