import {CheckIcon, PencilIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {Link, NavLink, useSearchParams} from 'react-router-dom'
import {twMerge} from 'tailwind-merge'
import {ArrowNarrowLeftIcon} from '~/assets/ArrowNarrowLeft.tsx'
import {UniqueNameRequiredModal} from '~/components/UniqueNameRequiredModal.tsx'
import {DataSet} from '~/model.ts'
import {useDataSets, useUpdateDataSet} from '~/services/api.ts'

interface DataSetsSideBarProps {
	dataSet: DataSet
}

export function DataSetsSideBar({dataSet}: DataSetsSideBarProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const {data: dataSets} = useDataSets()
	const {mutate: updateDataSetApi} = useUpdateDataSet()

	const [renameDataSetValue, setRenameDataSetValue] = useState<string | null>(null)
	const [uniqueNameRequiredOpen, setUniqueNameRequiredOpen] = useState(false)

	useEffect(() => {
		if (searchParams.has('rename')) {
			setRenameDataSetValue(dataSet.name)
			searchParams.delete('rename')
			setSearchParams(searchParams)
		}
	}, [searchParams, dataSet, setSearchParams])

	const renameDataSet = () => {
		if (renameDataSetValue == null || renameDataSetValue.length < 2) return
		if (
			dataSets?.personalDataSets.some((dataSetToCompare) => dataSetToCompare.name.toLowerCase() === renameDataSetValue.toLowerCase() && dataSet.SK != dataSetToCompare.SK) ??
			dataSets?.globalDataSets.some((dataSetToCompare) => dataSetToCompare.name.toLowerCase() === renameDataSetValue.toLowerCase())
		) {
			setUniqueNameRequiredOpen(true)
			return
		}
		updateDataSetApi({...dataSet, name: renameDataSetValue})
		setRenameDataSetValue(null)
	}

	return (
		<div className="flex w-[240px] flex-shrink-0 flex-col justify-between border-r bg-light-grey-25 dark:border-navy-50 dark:bg-navy-100">
			<div className="flex grow flex-col gap-y-[24px] px-[12px] py-[24px]">
				<div>
					<Link
						to="/datasets"
						className="flex max-w-max items-center gap-x-[12px] rounded-[6px] border border-transparent px-[12px] py-[4px] text-sm font-[600] hover:bg-white active:border-navy-100 active:bg-white dark:hover:bg-navy-75 dark:active:border-white dark:active:bg-navy-75"
					>
						<ArrowNarrowLeftIcon className="h-[16px]" />
						<div>Back</div>
					</Link>
					<h1 className="mt-[12px] flex h-[27px] items-center justify-between gap-x-[4px] text-lg font-[500]">
						{renameDataSetValue == null ? (
							<>
								<div className="max-w-[193px] grow break-words">{dataSet.name}</div>
								{dataSet.role !== 'VIEWER' && (
									<button
										className="flex h-[24px] w-[24px] items-center justify-center rounded-full border border-transparent hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-100"
										onClick={() => {
											setRenameDataSetValue(dataSet.name)
										}}
									>
										<PencilIcon className="h-[16px]" />
									</button>
								)}
							</>
						) : (
							<>
								<input
									type="text"
									value={renameDataSetValue}
									onChange={(event) => {
										setRenameDataSetValue(event.target.value)
									}}
									onKeyDown={(event) => {
										if (event.key === 'Enter') renameDataSet()
									}}
									className={twMerge(
										'mt-[-1px] w-full overflow-hidden rounded-[6px] border bg-white py-0 pl-[4px] text-lg font-[500] dark:bg-navy-100',
										renameDataSetValue.length < 2 && '!border-light-red-100',
									)}
									minLength={2}
									maxLength={50}
								/>
								<button
									onClick={() => {
										renameDataSet()
									}}
									className="ml-[8px] flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full border border-transparent hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-100"
								>
									<CheckIcon className="h-[16px]" />
								</button>
							</>
						)}
					</h1>
				</div>
				<div className="flex grow flex-col gap-y-[4px]">
					<NavLink
						to={`/datasets/${dataSet.SK}`}
						className={({isActive}) =>
							clsx(
								isActive ? 'border-navy-100 dark:border-white' : 'border-transparent',
								'group flex flex-shrink-0 items-center justify-between rounded-[6px] border hover:bg-white active:border-navy-100 dark:hover:bg-navy-75 active:dark:border-white',
							)
						}
						end
					>
						<div className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-[500] focus:outline-none">Details & configuration</div>
					</NavLink>

					<NavLink
						to={`/datasets/${dataSet.SK}/files`}
						className={({isActive}) =>
							clsx(
								isActive ? 'border-navy-100 dark:border-white' : 'border-transparent',
								'group flex flex-shrink-0 items-center justify-between rounded-[6px] border hover:bg-white active:border-navy-100 dark:hover:bg-navy-75 active:dark:border-white',
							)
						}
					>
						<div className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-[500] focus:outline-none">Data files</div>
					</NavLink>
				</div>
			</div>

			<UniqueNameRequiredModal
				open={uniqueNameRequiredOpen}
				onClose={() => {
					setUniqueNameRequiredOpen(false)
				}}
				itemType="data set"
			/>
		</div>
	)
}
