export const ArrowNarrowLeftIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.66667 10.6673L2 8.00065M2 8.00065L4.66667 5.33398M2 8.00065L14 8.00065"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
