import {PlusIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {useAtom} from 'jotai'
import {useRef, useState} from 'react'
import {CloseIcon} from '~/assets/CloseIcon.tsx'
import {Spinner2} from '~/assets/Spinner2.tsx'
import {ErrorModal} from '~/components/ErrorModal.tsx'
import {InfoModalUploadFiles} from '~/components/InfoButtonAndModal.tsx'
import {Attachment, FileState} from '~/model.ts'
import {convertBinaryFileToText, useReferenceData} from '~/services/api'
import {fileStatesAtom} from '~/services/state.ts'
import {convertBytesNumberToString, convertBytesSizeStringToNumber, fileToText} from '~/util'

const makeFileSafe = (filename: string): string => {
	return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase()
}

export const AttachFiles = () => {
	const fileInputRef = useRef<HTMLInputElement>(null)
	const {data: referenceData} = useReferenceData()
	const [filesStates, setFilesStates] = useAtom(fileStatesAtom)
	const [showError, setShowError] = useState(false)

	const fileStatesArray = Object.values(filesStates)
	const fileArray = fileStatesArray.map((filesState) => filesState.file)
	const hasFiles = fileStatesArray.length > 0
	const processingFiles = fileStatesArray.some((fileState) => fileState.status === 'processing')

	if (!referenceData) return null

	const maxFileSize = convertBytesSizeStringToNumber(referenceData.llm_features.file_upload.max_file_size)
	const maxCombinedFileSize = convertBytesSizeStringToNumber(referenceData.llm_features.file_upload.max_combined_file_size)
	const allowedTypes = `${referenceData.llm_features.file_upload.convert_file_types},${referenceData.llm_features.file_upload.text_file_types}`

	const addFiles = async (files: FileList | null) => {
		if (!files || files.length <= 0) return

		const newFiles = [...files]
		const newFileStates: Record<string, FileState> = {}
		newFiles.forEach((file) => {
			const safeFileName = makeFileSafe(file.name)
			newFileStates[safeFileName] = {file, status: 'processing'}
		})

		const newFileStatesCombined = {...filesStates, ...newFileStates}
		setFilesStates((prev) => ({...prev, ...newFileStates}))

		if (
			Object.values(newFileStatesCombined).length > referenceData.llm_features.file_upload.max_files ||
			Object.values(newFileStatesCombined).reduce((acc, fileState) => acc + fileState.file.size, 0) > maxCombinedFileSize
		) {
			setFilesStates({})
			setShowError(true)
			return
		}

		const fileProcessingPromises = newFiles.map(async (file) => {
			const safeFileName = makeFileSafe(file.name)
			if (file.size > maxFileSize) {
				setFilesStates((prev) => ({...prev, [safeFileName]: {...prev[safeFileName], status: 'error'}}))
				setShowError(true)
				return
			}

			try {
				let attachment: Attachment
				if (referenceData.llm_features.file_upload.text_file_types.includes(file.name.split('.').pop()?.toLowerCase() ?? '')) {
					attachment = {
						file_name: file.name,
						file_type: file.type,
						file_size: file.size,
						extracted_content: await fileToText(file),
					}
				} else {
					attachment = await convertBinaryFileToText(file)
				}
				setFilesStates((prev) => ({
					...prev,
					[safeFileName]: {...prev[safeFileName], status: 'processed', attachment},
				}))
			} catch (error) {
				setFilesStates((prev) => ({
					...prev,
					[safeFileName]: {...prev[safeFileName], status: 'error', conversionError: JSON.stringify(error, null, 2)},
				}))
				setShowError(true)
			}
		})

		await Promise.all(fileProcessingPromises)
	}

	const removeFile = (filename: string) => {
		setFilesStates((prev) => {
			const newFiles = {...prev}
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete newFiles[makeFileSafe(filename)]
			return newFiles
		})
	}

	return (
		<div>
			<input
				type="file"
				ref={fileInputRef}
				className="hidden"
				onChange={(event) => {
					void addFiles(event.target.files)
				}}
				multiple
				accept={allowedTypes}
			/>

			<div className="mt-3 flex items-center gap-2">
				{hasFiles && processingFiles && (
					<>
						<Spinner2 className="" />
						<div className="text-sm text-navy-75 dark:text-light-grey-50">Uploading...</div>
					</>
				)}
				<button
					className="flex items-center gap-2 rounded border border-transparent px-[8px] py-[2px] hover:bg-light-grey-25 active:border-navy-100 dark:hover:bg-navy-75 dark:active:border-white"
					onClick={() => fileInputRef.current?.click()}
				>
					<PlusIcon className="h-4 w-4" />
					<span>Add files</span>
				</button>
				<InfoModalUploadFiles />
			</div>

			{hasFiles && (
				<div className="mt-[12px] flex flex-wrap items-center gap-[4px]">
					{fileStatesArray.map((fileState) => {
						const isLoaded = fileState.status === 'processed'
						const nameSplit = fileState.file.name.split('.')
						return (
							<div
								key={fileState.file.name + fileState.status}
								className={clsx(isLoaded ? '' : 'animate-pulse', 'flex items-center gap-x-[4px] rounded-[4px] bg-white px-[8px] py-[2px] text-[14px] dark:bg-navy-75')}
							>
								<div>{fileState.file.name.length < 20 ? fileState.file.name : `${nameSplit[0]?.slice(0, 7)}...${nameSplit[0]?.slice(-6)}.${nameSplit[1]}`} //</div>
								<div className="font-[600]">{convertBytesNumberToString(fileState.file.size)}</div>
								{isLoaded && (
									<button
										onClick={() => {
											removeFile(fileState.file.name)
										}}
										className="flex items-center rounded-[4px] border border-transparent p-[4px] hover:bg-light-grey-25 active:border-navy-100 dark:hover:bg-navy-50 dark:active:border-white dark:active:bg-navy-50"
									>
										<CloseIcon className="size-[8px] light:text-navy-100" />
									</button>
								)}
							</div>
						)
					})}
				</div>
			)}
			<ErrorModal
				title="File attachment errors"
				open={showError}
				onClose={() => {
					setFilesStates({})
					setShowError(false)
				}}
			>
				<p>There are errors with the files you've attached to this chat</p>
				{fileStatesArray.length > referenceData.llm_features.file_upload.max_files && (
					<p className="mt-[1rem]">
						<strong>To many files:</strong> You have attached {fileStatesArray.length} files, this is over the limit of {referenceData.llm_features.file_upload.max_files}
					</p>
				)}
				{fileStatesArray.some((fileState) => fileState.file.size > maxFileSize) && (
					<div className="mt-[1rem]">
						<div>
							<strong>Files to large:</strong> These files are over the individual limit of {referenceData.llm_features.file_upload.max_file_size}
						</div>
						<div className="mt-[1rem] flex flex-wrap gap-[8px]">
							{fileArray
								.filter((file) => file.size > maxFileSize)
								.map((file, index) => {
									const nameSplit = file.name.split('.')
									return (
										<div
											key={index}
											className="flex items-center gap-x-[4px] rounded-[4px] bg-navy-75 px-[8px] py-[2px] text-[14px]"
										>
											<div className="font-[400]">{file.name.length < 40 ? file.name : `${nameSplit[0]?.slice(0, 3)}...${nameSplit[0]?.slice(-3)}.${nameSplit[1]}`} //</div>
											<div className="font-[600]">{convertBytesNumberToString(file.size)}</div>
										</div>
									)
								})}
						</div>
					</div>
				)}
				{fileArray.reduce((acc, file) => acc + file.size, 0) > maxCombinedFileSize && (
					<p className="mt-[1rem]">
						<strong>Combined file size too large:</strong> Your combined file size of {convertBytesNumberToString(fileArray.reduce((acc, file) => acc + file.size, 0))} is over the limit of{' '}
						{referenceData.llm_features.file_upload.max_combined_file_size}
					</p>
				)}
				{fileArray.some((file) => !allowedTypes.includes(file.name.split('.').pop() ?? 'no_extension')) && (
					<p className="mt-[1rem]">
						<strong>Files with not allowed extensions:</strong> The following file extensions that are not allowed:{' '}
						{fileArray
							.reduce<string[]>((acc, file) => {
								const extension = file.name.split('.').pop()?.toLowerCase() ?? ''
								if (!allowedTypes.includes(extension) && !acc.includes('.' + extension)) {
									acc.push('.' + extension)
								}
								return acc
							}, [])
							.join(', ')}
					</p>
				)}
				{fileStatesArray.some((fileState) => fileState.networkError != null) && (
					<p className="mt-[1rem]">
						<strong>Error converting file to text:</strong>
						{fileStatesArray
							.filter((fileState) => fileState.networkError != null)
							.map((fileState) => (
								<div key={fileState.file.name}>
									{fileState.file.name} : {fileState.networkError}
								</div>
							))}
					</p>
				)}
			</ErrorModal>
		</div>
	)
}
