import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {CheckMark} from '~/assets/CheckMark.tsx'

interface Props {
	threadId?: string
	isPlayground: boolean
	showSources?: boolean
	onShowSources?: () => void
	onShowThreadConfig: () => void
	onRenameThread: () => void
	onDeleteThread: () => void
}

export function ThreadMenu(props: Props) {
	return (
		<Menu
			as="div"
			className="absolute right-[64px] top-[30px]"
		>
			<MenuButton className="rounded-full border border-navy-75 p-[2px] hover:bg-light-grey-25 active:bg-white light:text-navy-75 dark:border-white dark:hover:bg-navy-75 dark:active:bg-navy-150">
				<EllipsisVerticalIcon className="h-[24px]" />
			</MenuButton>
			<MenuItems className="absolute right-0 top-[24px] z-10 mt-2 w-[234px] rounded-[6px] border bg-white p-1 text-sm shadow-lg dark:border-none dark:bg-navy-75">
				<MenuItem>
					<button
						className="block w-full rounded-[6px] p-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
						onClick={props.onShowThreadConfig}
					>
						Thread configuration
					</button>
				</MenuItem>
				{!props.isPlayground && (
					<MenuItem>
						<button
							onClick={props.onShowSources}
							className="flex w-full justify-between rounded-[6px] p-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
						>
							<div className="w-full text-left">Show source in thread</div>
							<div className="flex h-[16px] w-[16px] items-center justify-center rounded-[6px] border bg-white">
								{props.showSources && <CheckMark className="h-[12px] w-[12px] text-dark-grey-100" />}
							</div>
						</button>
					</MenuItem>
				)}
				{props.threadId != null && (
					<>
						<MenuItem>
							<button
								className="block w-full rounded-[6px] p-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
								onClick={props.onRenameThread}
							>
								Rename thread
							</button>
						</MenuItem>
						<MenuItem>
							<button
								onClick={props.onDeleteThread}
								className="block w-full rounded-[6px] p-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
							>
								Delete thread
							</button>
						</MenuItem>
					</>
				)}
			</MenuItems>
		</Menu>
	)
}
