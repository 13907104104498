import {twMerge} from 'tailwind-merge'

export const Spinner2 = ({className, backgroundRingClassName, foregroundRingClassName}: {className?: string; backgroundRingClassName?: string; foregroundRingClassName?: string}) => (
	<svg
		className={twMerge('size-[20px] animate-spin', className)}
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 20"
	>
		<path
			d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.11817 10C3.11817 13.8007 6.19927 16.8818 10 16.8818C13.8007 16.8818 16.8818 13.8007 16.8818 10C16.8818 6.19927 13.8007 3.11817 10 3.11817C6.19927 3.11817 3.11817 6.19927 3.11817 10Z"
			fill="currentColor"
			className={twMerge('opacity-30', backgroundRingClassName)}
		/>
		<path
			d="M18.4409 10C19.302 10 20.0124 9.29783 19.8787 8.44721C19.7563 7.66837 19.5419 6.90492 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C13.0951 0.4581 12.3316 0.243716 11.5528 0.121293C10.7022 -0.0124119 10 0.698026 10 1.55908V1.55908C10 2.42014 10.7067 3.10061 11.5458 3.29402C11.9165 3.37948 12.2804 3.49574 12.6336 3.64202C13.4685 3.98786 14.2272 4.49477 14.8662 5.13381C15.5052 5.77285 16.0121 6.5315 16.358 7.36644C16.5043 7.71959 16.6205 8.08347 16.706 8.45421C16.8994 9.29326 17.5799 10 18.4409 10V10Z"
			fill="currentColor"
			className={foregroundRingClassName}
		/>
	</svg>
)
