import {FolderIcon, SparklesIcon} from '@heroicons/react/24/solid'
import {Link} from 'react-router-dom'
import {Role} from '~/model.ts'

interface EmptyThreadMessageParams {
	isPlayground: boolean | undefined
	role: Role
	onShowProjectConfig?: () => void
}

export function EmptyThreadMessage(props: EmptyThreadMessageParams) {
	return (
		<div className="px-3">
			<div className="mx-auto w-full max-w-content-sm">
				<div className="mt-[72px] flex gap-x-[24px] rounded-[6px] border bg-light-grey-25 px-[24px] py-[16px] dark:bg-navy-100">
					<div className="flex-shrink-0">
						<div className="rounded-[6px] bg-yellow-100 p-[8px] text-navy-100">
							{props.isPlayground ? <SparklesIcon className="h-[24px] w-[24px]" /> : <FolderIcon className="h-[24px] w-[24px]" />}
						</div>
					</div>
					<div className="text-navy-75 dark:text-white">
						{props.isPlayground ? (
							<>
								<p>
									Secure chat is similar to ChatGPT, however, this is an internal University of Melbourne product. You can input query information that may be sensitive to the University as it meets
									University policy on data management
								</p>
							</>
						) : (
							<>
								<p>
									<strong>Projects</strong> enable you to harness the capabilities of Large Language Model (LLM) technology while working with highly specific data sets. You have the option to utilise
									preconfigured (global) data sets or{' '}
									<Link
										to="/datasets"
										className="underline"
									>
										incorporate your own
									</Link>
									.
								</p>
								{props.role !== 'VIEWER' && (
									<p className="mt-[1rem]">
										Customise your project-specific variables within the{' '}
										<button
											className="underline"
											onClick={props.onShowProjectConfig}
										>
											project configuration
										</button>{' '}
										controls.
									</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
