import {ExclamationTriangleIcon} from '@heroicons/react/24/solid'

export const ProcessingError = () => {
	return (
		<div className="bg-light-red-10 mt-[48px] flex max-w-content-sm gap-x-[12px] rounded-r-[6px] border-l-4 border-light-red-100 p-[16px]">
			<ExclamationTriangleIcon className="h-[27px] text-light-red-100" />
			<div className="text-light-red-125 text-sm font-[500]">
				Processing error. Please attempt to process your files again, or{' '}
				<a
					href="https://unimelb.service-now.com/kb_view.do?sysparm_article=KB0029850"
					className="underline"
				>
					read more about why this error may have occurred.
				</a>
			</div>
		</div>
	)
}
