import {atom} from 'jotai'
import {FileState} from '~/model.ts'

export const fileStatesAtom = atom<Record<string, FileState>>({})

/*export const selectFilesStateAtom = atom((get) => {
	const selectedFiles = Object.values(get(selectedFilesAtom))

	return {
		someNotProcessed: selectedFiles.some((file) => file.status !== 'processed'),
		isFiles: selectedFiles.length > 0,
	}
})

export const there = atom((get) => {
	return Object.values(get(selectedFilesAtom)).some((file) => file.status !== 'processed')
})*/
