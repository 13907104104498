import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {useMemo, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {GroupIcon} from '~/assets/GroupIcon.tsx'
import {PlusIcon} from '~/assets/PlusIcon.tsx'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {type NewProject, type Project} from '~/model.ts'
import {ProjectConfig} from '~/pages/project/ProjectConfig.tsx'
import {ProjectCreate} from '~/pages/project/ProjectCreate.tsx'
import {ProjectInfo} from '~/pages/project/ProjectInfo.tsx'
import {useCreateProject, useDataSets, useDeleteProject, useProjects, useUpdateProject} from '~/services/api.ts'
import {capitalizeFirstChar, formatDate} from '~/util.ts'

export const Projects = () => {
	const navigate = useNavigate()

	const {data: projects, isLoading: projectsIsLoading} = useProjects()
	const {data: dataSets} = useDataSets()
	const {mutateAsync: createProjectApi, isPending: createProjectIsPending} = useCreateProject()
	const {mutate: deleteProjectApi} = useDeleteProject()
	const {mutate: updateProjectApi} = useUpdateProject()

	const dataSetsCombined = dataSets != null ? [...dataSets.personalDataSets, ...dataSets.globalDataSets] : []

	const [createProjectOpen, setCreateProjectOpen] = useState(false)
	const [projectConfig, setProjectConfig] = useState<Project | null>(null)
	const [projectInfo, setProjectInfo] = useState<Project | null>(null)
	const [deleteProject, setDeleteProject] = useState<Project | null>(null)

	const projectsSortedByModifiedDatetime = useMemo(() => projects?.slice().sort((a, b) => (a.modified_datetime > b.modified_datetime ? -1 : 1)), [projects])

	function creatProject(newProject: NewProject) {
		setCreateProjectOpen(false)
		void createProjectApi(newProject).then((response) => {
			navigate(`/projects/${response.SK}`)
		})
	}

	if (createProjectIsPending) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="h-full overflow-y-auto px-[120px] py-[24px]">
				<h1 className="font-display text-2xl">Projects</h1>
				<p className="mt-[8px] max-w-content text-[18px] text-dark-grey-100 dark:text-light-grey-50">
					Projects enable you to harness the capabilities of Large Language Models (LLM) while working with highly specific data sets.
				</p>
				{projectsIsLoading ? (
					<div className="flex items-center justify-center">
						<LoadingAnimation />
					</div>
				) : (
					<div className="mt-[48px] flex flex-wrap gap-[24px]">
						{projectsSortedByModifiedDatetime?.map((project) => (
							<Link
								key={project.name}
								to={`/projects/${project.SK}?latest`}
								className="group w-[384px] rounded-[8px] border border-dark-grey-50 bg-white p-[24px] ring-1 ring-transparent hover:border-dark-grey-100 active:ring-dark-grey-100 dark:border-navy-50 dark:bg-navy-100 dark:hover:border-white dark:active:ring-white"
							>
								<div className="flex items-center justify-between">
									<div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-[600] dark:text-white">{project.name}</div>
									<Menu
										as="div"
										className="relative"
									>
										<MenuButton className="mx-[4px] flex items-center justify-center rounded-full border border-transparent p-[2px] group-hover:bg-light-grey-25 data-[open]:bg-light-grey-25 dark:group-hover:bg-navy-75 dark:data-[open]:bg-navy-75">
											<EllipsisVerticalIcon className="h-[20px]" />
										</MenuButton>
										<MenuItems className="absolute left-[2px] top-[20px] z-10 mt-2 w-[200px] rounded-[6px] border bg-white p-1 text-sm shadow-lg ring-black ring-opacity-5 dark:border-none dark:bg-navy-75">
											{project.role !== 'VIEWER' && (
												<>
													<MenuItem>
														<button
															onClick={(event) => {
																setProjectConfig(project)
																event.preventDefault()
															}}
															className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
														>
															Rename project
														</button>
													</MenuItem>
													<MenuItem>
														<button
															onClick={(event) => {
																setProjectConfig(project)
																event.preventDefault()
															}}
															className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
														>
															Project configuration
														</button>
													</MenuItem>
												</>
											)}
											<MenuItem>
												<button
													onClick={(event) => {
														setProjectInfo(project)
														event.preventDefault()
													}}
													className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
												>
													Project information
												</button>
											</MenuItem>
											{project.role === 'OWNER' && (
												<MenuItem>
													<button
														onClick={(event) => {
															setDeleteProject(project)
															event.preventDefault()
														}}
														className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
													>
														Delete project
													</button>
												</MenuItem>
											)}
										</MenuItems>
									</Menu>
								</div>
								<div className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">Last update: {formatDate(project.modified_datetime)}</div>
								<div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-dark-grey-75 dark:text-light-grey-50">
									Data set: {dataSetsCombined.find((dataset) => dataset.SK === project.dataset)?.name}
								</div>
								<div className="text-dark-grey-75 dark:text-light-grey-50">Type: {project.project_type ?? 'RAG'}</div>
								<div className="mt-[12px] flex max-w-max items-center justify-start gap-[4px] rounded-[4px] bg-light-grey-25 px-[8px] py-[2px] dark:bg-navy-75">
									<GroupIcon className="size-[16px]" />
									<div className="text-sm">{capitalizeFirstChar(project.role)}</div>
								</div>
							</Link>
						))}
						<button
							onClick={() => {
								setCreateProjectOpen(true)
							}}
							className="flex min-h-[178px] w-[384px] items-center justify-center gap-x-[8px] rounded-[8px] border border-dashed border-dark-grey-50 bg-white p-[24px] hover:border-dark-grey-100 active:border-2 active:border-navy-100 dark:border-navy-50 dark:bg-navy-100 dark:text-white dark:hover:border-white"
						>
							<PlusIcon className="h-[20px]" />
							<div className="text-sm font-[600]">Add project</div>
						</button>
					</div>
				)}
			</div>

			<ProjectCreate
				open={createProjectOpen}
				onCancel={() => {
					setCreateProjectOpen(false)
				}}
				onCreateProject={creatProject}
			/>

			<ProjectConfig
				open={projectConfig != null}
				isPlayground={false}
				config={projectConfig ?? undefined}
				onCancel={() => {
					setProjectConfig(null)
				}}
				onApply={(projectConfig) => {
					updateProjectApi(projectConfig)
					setProjectConfig(null)
				}}
			/>

			<ProjectInfo
				project={projectInfo ?? undefined}
				onDone={() => {
					setProjectInfo(null)
				}}
				onShowConfig={() => {
					setProjectConfig(projectInfo)
					setProjectInfo(null)
				}}
			/>

			<DeleteConfirmationModal
				open={deleteProject != null}
				itemType="project"
				onClose={() => {
					setDeleteProject(null)
				}}
				onDelete={() => {
					if (deleteProject != null) {
						deleteProjectApi(deleteProject)
					}
					setDeleteProject(null)
				}}
			/>
		</>
	)
}
