import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {SparkIcon} from '~/assets/SparkIcon.tsx'
import {AttachedFilesInfo} from '~/components/AttachedFilesInfo.tsx'
import {MessageInfo} from '~/components/MessageInfo.tsx'
import {Attachment, type UIMessage} from '~/model.ts'
import {MessageRagSources} from '~/pages/project/MessageRagSources.tsx'
import {useUserInitials} from '~/services/auth.ts'
import {convertBytesNumberToString, isValidHttpUrl} from '~/util.ts'

interface MessagesProps {
	messages: UIMessage[]
	showSources?: boolean
	isPlayground?: boolean
	pendingMessage: string | null
}

export const Messages = ({messages, showSources, isPlayground, pendingMessage}: MessagesProps) => {
	const userInitials = useUserInitials()

	const [showMessageInfo, setShowMessageInfo] = useState<UIMessage | null>(null)
	const [showMessageRagSources, setShowMessageRagSources] = useState<UIMessage | null>(null)

	const [showAttachments, setShowAttachments] = useState<Attachment[] | null>(null)
	const [showAttachmentIndex, setShowAttachmentIndex] = useState<number | null>(null)

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const pendingResponseRef = useRef<HTMLDivElement>(null)
	const endOfMessagesRef = useRef<HTMLDivElement>(null)

	// These two are separate because we want to scroll to the bottom of the thread instant when the messages change, but we want to scroll to the pending message when it changes
	useEffect(() => {
		scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight)
	}, [scrollContainerRef, messages])

	useEffect(() => {
		pendingResponseRef.current?.scrollIntoView({behavior: 'smooth'})
		endOfMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
	}, [pendingMessage])

	return (
		<>
			<div
				ref={scrollContainerRef}
				className="h-full overflow-y-auto px-3"
			>
				<div className="mx-auto flex w-full max-w-[724px] flex-col gap-y-[24px] py-[24px]">
					{messages.map((message, index) => (
						<div
							key={index}
							className="group flex gap-x-[24px]"
						>
							<div className={clsx(message.role === 'user' ? 'bg-light-blue-100 text-sm font-[500]' : 'bg-yellow-100', 'flex size-[40px] shrink-0 items-center justify-center rounded-full')}>
								{message.role === 'user' ? userInitials : <SparkIcon className="h-[22px]" />}
							</div>
							<div className="">
								{message.attachments != null && message.attachments.length > 0 && (
									<div className="mb-[14px] flex flex-wrap gap-[4px]">
										{message.attachments.map((attachment, index) => {
											const nameSplit = attachment.file_name.split('.')
											return (
												<button
													key={index}
													onClick={() => {
														setShowAttachments(message.attachments ?? null)
														setShowAttachmentIndex(index)
													}}
													className={clsx(
														'flex items-center gap-x-[4px] rounded-[4px] border border-transparent bg-light-grey-25 px-[8px] py-[2px] text-[14px] hover:bg-light-grey-50 active:border-navy-100 active:bg-light-grey-50 dark:bg-navy-100 dark:hover:bg-navy-75 dark:active:border-white dark:active:bg-navy-75',
													)}
												>
													<div className="font-[400]">{attachment.file_name.length < 40 ? attachment.file_name : `${nameSplit[0]?.slice(0, 7)}...${nameSplit[0]?.slice(-6)}.${nameSplit[1]}`} //</div>
													<div className="font-[600]">{convertBytesNumberToString(attachment.file_size)}</div>
												</button>
											)
										})}
									</div>
								)}
								<div className="whitespace-pre-line break-words rounded-[8px] bg-light-grey-25 p-[8px] dark:bg-navy-100">{message.converted_content ?? message.content}</div>

								{showSources && message.sources?.length != null && message.sources.length > 0 && (
									<div className="mt-[12px] max-w-max space-y-[24px] rounded-[8px] bg-light-grey-25 p-[8px] dark:bg-navy-100">
										{message.sources.map((source) => (
											<div
												key={source.uri}
												className="flex flex-col gap-y-[8px]"
											>
												<div className="break-all text-sm font-[500] text-dark-grey-100 dark:text-white">{source.title}</div>
												<div className="flex items-start gap-x-[8px] text-xs font-[500] text-dark-grey-100 dark:text-white">
													<div className="font-[600]">Source:</div>{' '}
													{isValidHttpUrl(source.source) ? (
														<a
															href={source.source}
															className="break-all text-navy-100 underline hover:text-navy-75 dark:text-yellow-100 dark:hover:text-yellow-75"
														>
															{source.source}
														</a>
													) : (
														<div className="break-all">{source.source}</div>
													)}
												</div>
												<div className="flex items-start gap-x-[20px] rounded-[4px] bg-light-blue-25 px-[10px] py-[2px] text-xs font-[500] text-navy-100 dark:bg-yellow-100 dark:text-navy-100">
													<div>URI:</div>
													{isValidHttpUrl(source.uri) ? (
														<a
															href={source.uri}
															className="break-all text-navy-100 underline hover:text-navy-75 dark:text-navy-100 dark:hover:text-navy-75"
														>
															{source.uri}
														</a>
													) : (
														<div className="break-all">{source.uri}</div>
													)}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="ml-auto">
								{message.role === 'assistant' && (
									<Menu
										as="div"
										className="relative z-[999]"
									>
										<MenuButton className="invisible flex items-center justify-center rounded-full p-[2px] hover:bg-light-grey-25 active:bg-white group-hover:visible data-[open]:visible dark:hover:bg-navy-75 dark:active:bg-navy-150">
											<EllipsisVerticalIcon className="h-[20px]" />
										</MenuButton>
										<MenuItems className="absolute right-0 top-[20px] mt-2 w-[200px] rounded-[6px] border bg-white p-1 text-sm shadow-lg ring-black ring-opacity-5 dark:border-none dark:bg-navy-75">
											<MenuItem>
												<button
													onClick={() => {
														setShowMessageInfo(message)
													}}
													className="block w-full rounded-[6px] px-4 py-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
												>
													Message information
												</button>
											</MenuItem>
											{!isPlayground && (
												<MenuItem>
													<button
														onClick={() => {
															setShowMessageRagSources(message)
														}}
														className="block w-full rounded-[6px] px-4 py-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
													>
														RAG sources
													</button>
												</MenuItem>
											)}
										</MenuItems>
									</Menu>
								)}
							</div>
						</div>
					))}
					{pendingMessage != null && (
						<>
							<div className="group flex gap-x-[24px]">
								<div className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-light-blue-100">{userInitials}</div>
								<div className="mt-[5px] whitespace-pre-line break-words bg-light-grey-25 p-[8px] dark:bg-navy-100">{pendingMessage}</div>
							</div>
							<div
								ref={pendingResponseRef}
								className="group flex gap-x-[24px]"
							>
								<div className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-yellow-100">
									<SparkIcon className="h-[22px]" />
								</div>
								<div className="relative flex items-center justify-center pl-[15px] pt-[5px]">
									<div className="dot-flashing" />
								</div>
							</div>
						</>
					)}
				</div>
				<div ref={endOfMessagesRef} />
			</div>

			<MessageInfo
				message={showMessageInfo ?? undefined}
				onDone={() => {
					setShowMessageInfo(null)
				}}
			/>

			<MessageRagSources
				message={showMessageRagSources ?? undefined}
				onDone={() => {
					setShowMessageRagSources(null)
				}}
			/>

			<AttachedFilesInfo
				attachments={showAttachments}
				showAttachmentIndex={showAttachmentIndex}
				onDone={() => {
					setShowAttachments(null)
				}}
			/>
		</>
	)
}
