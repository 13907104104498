import {useAuth} from 'react-oidc-context'
import {LoadingAnimationCentered} from '~/components/LoadingAnimation.tsx'
import {useUserInfo} from '~/services/auth.ts'

export const Settings = () => {
	const auth = useAuth()
	const {userInfo} = useUserInfo()

	if (!userInfo) return <LoadingAnimationCentered />

	return (
		<div className="flex grow">
			<div className="flex w-[240px] flex-shrink-0 flex-col justify-between border-r bg-light-grey-25 dark:border-navy-50 dark:bg-navy-100">
				<div className="flex grow flex-col gap-y-[24px] px-[12px] py-[24px]">
					<div>
						<h1 className="mt-[12px] flex h-[27px] items-center justify-between gap-x-[4px] text-lg font-[500]">
							<div className="max-w-[193px] grow break-words">Settings</div>
						</h1>
					</div>
					<div className="flex grow flex-col gap-y-[4px]">
						<div className="group flex flex-shrink-0 items-center justify-between rounded-[6px] border border-navy-100 hover:bg-white active:border-navy-100 dark:border-white dark:hover:bg-navy-75 active:dark:border-white">
							<div className="flex-1 cursor-pointer py-[8px] pl-[8px] text-left text-sm font-[500] focus:outline-none">Profile</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex grow flex-col">
				<div className="grow overflow-y-auto px-[84px] py-[24px]">
					<h1 className="font-display text-2xl">Profile</h1>
					<p className="mt-[8px] max-w-content text-lg text-dark-grey-100 dark:text-light-grey-50">View and manage your profile information.</p>
					<div className="mt-[24px] flex w-full max-w-content-sm gap-[24px] rounded-[8px] border p-[24px] text-sm">
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">First name</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{userInfo.given_name}</div>
						</div>
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">Last name</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{userInfo.family_name}</div>
						</div>
						<div className="w-full max-w-[130px]">
							<div className="pb-[4px] font-[500] dark:text-white">Email address</div>
							<div className="text-dark-grey-100 dark:text-light-grey-50">{auth.user?.profile.email}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
