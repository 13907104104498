import {Draw} from '~/components/Draw.tsx'
import {type Project} from '~/model.ts'
import {useDataSet} from '~/services/api.ts'
import {formatDate} from '~/util.ts'

interface Props {
	project?: Project
	onDone: () => void
	onShowConfig?: () => void
}

export const ProjectInfo = ({project, onDone, onShowConfig}: Props) => {
	const {data: dataSet} = useDataSet(project?.dataset)

	return (
		<Draw
			open={project != null}
			onDone={onDone}
		>
			<div className="overflow-y-auto px-[120px] py-[24px] text-sm">
				<div className="max-w-content">
					<h2 className="font-display text-2xl">Project information</h2>
					<p className="mt-[8px] text-[18px] light:text-dark-grey-100">
						These details showcase the current configuration and token usage of this specific project.{' '}
						{project?.role !== 'VIEWER' && (
							<>
								Edit these values in{' '}
								<button
									className="underline"
									onClick={onShowConfig}
								>
									project configuration
								</button>
								.
							</>
						)}
					</p>
				</div>

				<div className="mt-[48px] text-sm font-[600] light:text-dark-grey-100">Name</div>
				<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">{project?.name}</p>

				<div className="mt-[12px] text-sm font-[600] light:text-dark-grey-100">Description</div>
				{project?.description != null && project.description.length > 0 ? (
					<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">{project.description}</p>
				) : (
					<p className="mt-[8px] text-dark-grey-25 dark:text-gray-500">(No description provided)</p>
				)}

				<div className="mt-[24px] flex w-full max-w-content-sm gap-[24px] rounded-[8px] border p-[24px] text-sm">
					<div className="w-full max-w-[130px]">
						<div className="pb-[4px] font-[500] dark:text-white">Data set</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{dataSet?.name}</div>
					</div>
					<div className="w-full max-w-[130px]">
						<div className="pb-[4px] font-[500] dark:text-white">Dataset Last updated</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{dataSet != null && formatDate(dataSet.modified_datetime)}</div>
					</div>
					<div className="w-full max-w-[130px]">
						<div className="pb-[4px] font-[500] dark:text-white">Chunk size</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{dataSet?.vector_configuration.chunk_size}</div>
					</div>
				</div>

				<h3 className="mt-[48px] block text-lg font-[600] light:text-navy-100">Custom instructions</h3>
				<div className="mt-[12px] text-sm font-[600] light:text-dark-grey-100">What would you like the AI model to know to provide better responses?</div>
				<textarea
					disabled
					className="mt-[4px] w-full max-w-content-sm rounded-[6px] !border-navy-25 text-sm text-dark-grey-75 dark:!border-navy-50 dark:bg-navy-150 dark:text-light-grey-50"
					rows={6}
					value={project?.prompt_templates.default.prompt_ai_to_know}
				/>
			</div>
		</Draw>
	)
}
