export const InfoIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.9 6.99961C12.9 10.2581 10.2585 12.8996 6.99999 12.8996C3.7415 12.8996 1.09998 10.2581 1.09998 6.99961C1.09998 3.74113 3.7415 1.09961 6.99999 1.09961C10.2585 1.09961 12.9 3.74113 12.9 6.99961ZM6.99999 5.09961C7.71795 5.09961 8.29999 4.51758 8.29999 3.79961C8.29999 3.08164 7.71795 2.49961 6.99999 2.49961C6.28202 2.49961 5.69998 3.08164 5.69998 3.79961C5.69998 4.51758 6.28202 5.09961 6.99999 5.09961ZM6.19998 5.69961C5.48201 5.69961 4.89998 6.28164 4.89998 6.99961C4.89998 7.5404 5.23019 8.00406 5.69998 8.19998V10.1996C5.69998 10.9176 6.28201 11.4996 6.99999 11.4996H7.79999C8.51796 11.4996 9.09999 10.9176 9.09999 10.1996C9.09999 9.65882 8.76978 9.19515 8.29999 8.99924V6.99961C8.29999 6.28164 7.71795 5.69961 6.99999 5.69961H6.19998Z"
			fill="currentColor"
		/>
	</svg>
)
