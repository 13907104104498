import {Dialog, DialogPanel, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel, Transition, TransitionChild} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon, DocumentDuplicateIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {Fragment, useEffect, useRef, useState} from 'react'
import {useAuth} from 'react-oidc-context'
import {redirect} from 'react-router-dom'
import {useInterval} from 'usehooks-ts'
import {axiosAuthInstance, queryClient} from '~/services/api.ts'

interface NetworkError {
	config?: {
		method?: string
	}
	request?: {
		responseURL?: string
	}
	response?: {
		status?: number
		headers?: {
			'x-amzn-requestid'?: string
		}
		data?: {
			message?: string
		}
	}
}

export const NetworkErrorHandling = () => {
	const okayButtonRef = useRef(null)

	const [error, setError] = useState<{requestId?: string; url?: string; method?: string; status?: number; message?: string} | null>(null)
	const [copied, setCopied] = useState(false)

	const auth = useAuth()

	useInterval(
		() => {
			setCopied(false)
		},
		copied ? 5000 : null,
	)

	// Create a response interceptor for error handling
	useEffect(() => {
		axiosAuthInstance.interceptors.response.use(
			(response) => {
				return response
			},
			(rejectedError) => {
				const typedError = rejectedError as NetworkError
				if (403 === typedError.response?.status) {
					void auth.removeUser()
					void auth.signinRedirect()
				} else {
					if (error == null && performance.now() > 1000) {
						setError({
							url: typedError.request?.responseURL,
							method: typedError.config?.method,
							requestId: typedError.response?.headers == null ? undefined : typedError.response.headers['x-amzn-requestid'],
							status: typedError.response?.status,
							message: typedError.response?.data?.message,
						})
					}
					return Promise.reject(new Error('An error occurred while communicating with the server.'))
				}
			},
		)
	})

	const onClose = () => {
		setError(null)
	}

	const onOkay = () => {
		setError(null)
		queryClient.clear()
		localStorage.clear()
		if (location.pathname.startsWith('/securechat')) {
			redirect('/securechat')
		}
		if (location.pathname.startsWith('/projects')) {
			redirect('/projects')
		}
		if (location.pathname.startsWith('/datasets')) {
			redirect('/datasets')
		}
	}

	function copyErrorDetailsToClipboard() {
		setCopied(true)
		if (error == null) {
			return
		}
		const text = `Request Id: ${error.requestId} \nMethod: ${error.method} \nURL: ${error.url} \nStatus: ${error.status} \nMessage: ${error.message}`
		void navigator.clipboard.writeText(text)
	}

	return (
		<>
			{error != null && (
				<Transition
					show
					as={Fragment}
					appear
				>
					<Dialog
						as="div"
						className="relative z-10"
						initialFocus={okayButtonRef}
						onClose={onClose}
					>
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
						</TransitionChild>

						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<TransitionChild
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<DialogPanel className="relative m-8 w-full max-w-[512px] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all dark:bg-navy-100">
										<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
											<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-light-grey-25 sm:mx-0 dark:bg-navy-75">
												<ExclamationTriangleIcon
													className="h-[24px] light:text-dark-grey-100"
													aria-hidden="true"
												/>
											</div>
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<DialogTitle
													as="h3"
													className="text-lg font-[600] light:text-dark-grey-100"
												>
													{error.status === 408 ? 'Request timed out' : 'Server error'}
												</DialogTitle>
												<div className="mt-2 text-dark-grey-75 dark:text-light-grey-50">
													<p>
														{error.status === 408 ? 'The server has timed out.' : 'An error occurred while communicating with the server.'} The application will delete it's local cache and reload the
														page.
													</p>
													{error.requestId != null && (
														<>
															<p className="mt-[1rem]">Please provide the following request id to technical support when you contact them:</p>
															<div className="mt-[.5rem] break-all">{error.requestId}</div>
														</>
													)}
													{error.status != null && (
														<Disclosure>
															{({open}) => (
																<>
																	<DisclosureButton className="flex items-center pb-2 pt-4 font-[600] light:text-dark-grey-100">
																		Technical Details <ChevronDownIcon className={clsx(open && 'rotate-180', 'h-[1rem] px-[8px]')} />
																	</DisclosureButton>
																	<DisclosurePanel className="text-dark-grey-75 dark:text-gray-200">
																		<div className="grid grid-cols-[auto_auto] gap-x-[8px] gap-y-[4px]">
																			<div className="">Method:</div>
																			<div className="uppercase">{error.method}</div>
																			<div className="">URL:</div>
																			<div className="">{error.url}</div>
																			<div className="">Status:</div>
																			<div className="">{error.status}</div>
																			{error.message != null && (
																				<>
																					<div className="">Message:</div>
																					<div className="">{error.message}</div>
																				</>
																			)}
																		</div>
																	</DisclosurePanel>
																</>
															)}
														</Disclosure>
													)}
												</div>
											</div>
										</div>
										<div className="flex flex-col justify-end gap-3 bg-light-grey-25 px-[24px] py-[12px] text-sm font-[500] sm:flex-row dark:bg-navy-75">
											<button
												type="button"
												className="flex shrink-0 items-center justify-center gap-x-[8px] rounded-[4px] border border-navy-100 px-[11px] py-[7px] text-xs font-[500] light:bg-white light:hover:bg-light-grey-50 light:active:bg-light-grey-75 dark:border-white dark:hover:bg-navy-75 dark:active:bg-navy-150"
												onClick={copyErrorDetailsToClipboard}
											>
												{copied ? <CheckIcon className="h-5 w-5" /> : <DocumentDuplicateIcon className="h-5 w-5" />}
												<span>{copied ? 'Copied' : 'Copy technical details'}</span>
											</button>
											<button
												type="button"
												className="flex w-full items-center justify-center rounded-[6px] bg-light-blue-100 px-3 py-2 hover:bg-light-blue-75 active:bg-light-blue-50 sm:w-auto dark:bg-white dark:text-navy-100 dark:hover:bg-light-grey-25 dark:active:bg-light-grey-50"
												onClick={onOkay}
												ref={okayButtonRef}
											>
												Okay
											</button>
										</div>
									</DialogPanel>
								</TransitionChild>
							</div>
						</div>
					</Dialog>
				</Transition>
			)}
		</>
	)
}
