import {useAtom} from 'jotai/index'
import {atomWithStorage} from 'jotai/utils'
import {useEffect} from 'react'

export const DARK_MODE_LOCAL_STORAGE_KEY = 'dark-mode'

export const darkModeAtom = atomWithStorage(DARK_MODE_LOCAL_STORAGE_KEY, window.matchMedia('(prefers-color-scheme: dark)').matches)

export const useTheme = () => {
	const [darkMode, setDarkMode] = useAtom(darkModeAtom)

	useEffect(() => {
		const root = window.document.documentElement
		if (darkMode) {
			root.classList.add('dark')
		} else {
			root.classList.remove('dark')
		}
	}, [darkMode])

	function toggleTheme() {
		setDarkMode((previous) => !previous)
	}

	return {darkMode, setDarkMode, toggleTheme}
}
