import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {CheckIcon, PencilIcon} from '@heroicons/react/24/outline'
import {Cog6ToothIcon, EllipsisVerticalIcon, PlusIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {twMerge} from 'tailwind-merge'
import {ArrowNarrowLeftIcon} from '~/assets/ArrowNarrowLeft.tsx'
import {UniqueNameRequiredModal} from '~/components/UniqueNameRequiredModal.tsx'
import {type Project} from '~/model.ts'
import {useProjects, useRenameThread, useThreads, useUpdateProject} from '~/services/api.ts'

interface ProjectSidebarProps {
	isPlayground: boolean
	project?: Project
	setDeleteThreadId: (id: string) => void
	showProjectConfig: () => void
	renameThreadId: string | null
	setRenameThreadId: (id: string | null) => void
}

export const ProjectSidebar = ({isPlayground, project, showProjectConfig, setDeleteThreadId, renameThreadId, setRenameThreadId}: ProjectSidebarProps) => {
	const {projectId, threadId} = useParams()

	const {data: threads} = useThreads(projectId)
	const {data: projects} = useProjects()
	const {mutate: mutateRenameThread} = useRenameThread()
	const {mutate: updateProjectApi} = useUpdateProject()

	const [renameThreadValue, setRenameThreadValue] = useState<string | null>(null)
	const [renameProjectValue, setRenameProjectValue] = useState<string | null>(null)
	const [uniqueNameRequiredType, setUniqueNameRequiredType] = useState<string | null>(null)
	const [threadListScrollHeight, setThreadListScrollHeight] = useState<number>(0)

	const threadListScrollRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (renameThreadId != null && threads != null) {
			const thread = threads.find((thread) => thread.SK === renameThreadId)
			setRenameThreadValue(thread?.thread_name ?? null)
		}
	}, [renameThreadId, threads])

	const renameThread = () => {
		if (renameThreadId == null || renameThreadValue == null || renameThreadValue.length <= 0) return

		if (threads?.some((thread) => thread.thread_name === renameThreadValue && thread.SK !== renameThreadId)) {
			setUniqueNameRequiredType('thread')
			return
		}

		mutateRenameThread({projectId: project?.SK, threadId: renameThreadId, newName: renameThreadValue})
		setRenameThreadValue(null)
		setRenameThreadId(null)
	}

	const renameProject = () => {
		if (project == null || renameProjectValue == null || renameProjectValue.length < 2) return

		if (projects?.some((comparisonProject) => comparisonProject.name.toLowerCase() === renameProjectValue && comparisonProject.SK !== project.SK)) {
			setUniqueNameRequiredType('project')
			return
		}

		updateProjectApi({...project, name: renameProjectValue})
		setRenameProjectValue(null)
	}

	return (
		<>
			<div className="flex w-[240px] flex-shrink-0 flex-col justify-between border-r bg-light-grey-25 dark:border-navy-50 dark:bg-navy-100">
				<div className="flex grow flex-col gap-y-[24px] px-[12px] py-[24px]">
					<div>
						{!isPlayground && (
							<Link
								to="/projects"
								className="flex max-w-max items-center gap-x-[12px] rounded-[6px] border border-transparent px-[12px] py-[4px] text-sm hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-100"
							>
								<ArrowNarrowLeftIcon className="h-[16px]" />
								<div>Back</div>
							</Link>
						)}
						<h1 className="mt-[12px] flex h-[27px] items-center justify-between gap-x-[4px] text-lg font-[500]">
							{renameProjectValue == null ? (
								<>
									<div className="max-w-[193px] grow break-words">{isPlayground ? 'Secure chat' : project?.name}</div>
									{!isPlayground && project?.role !== 'VIEWER' && (
										<button
											className="flex h-[24px] w-[24px] items-center justify-center rounded-full border border-transparent hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-100"
											onClick={() => {
												setRenameProjectValue(project?.name ?? null)
											}}
										>
											<PencilIcon className="h-[16px]" />
										</button>
									)}
								</>
							) : (
								<>
									<input
										type="text"
										value={renameProjectValue}
										onChange={(event) => {
											setRenameProjectValue(event.target.value)
										}}
										onKeyDown={(event) => {
											if (event.key === 'Enter') renameProject()
										}}
										className={twMerge(
											'mt-[-1px] w-full overflow-hidden rounded-[6px] border bg-white py-0 pl-[4px] text-lg font-[500] dark:bg-navy-100',
											renameProjectValue.length < 2 && '!border-light-red-100',
										)}
										minLength={2}
										maxLength={50}
									/>
									<button
										onClick={() => {
											renameProject()
										}}
										className="ml-[8px] flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full border border-transparent hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-100"
									>
										<CheckIcon className="h-[16px]" />
									</button>
								</>
							)}
						</h1>
					</div>
					<Link
						to={isPlayground ? '/securechat' : `/projects/${project?.SK}`}
						className="flex w-full items-center gap-x-[8px] rounded-[6px] bg-light-blue-100 px-[16px] py-[9px] text-sm font-[600] text-navy-100 hover:bg-light-blue-75 active:bg-light-blue-100 dark:bg-white dark:hover:bg-light-grey-25 dark:active:bg-light-grey-50"
					>
						<PlusIcon className="h-[16px]" />
						<div>New thread</div>
					</Link>
					<div
						ref={threadListScrollRef}
						className="skinny-scrollbar flex grow flex-col gap-y-[4px] overflow-y-auto"
					>
						{threads?.map((thread) => (
							<div
								key={thread.SK}
								className={clsx(
									thread.SK === threadId ? 'border-navy-100 dark:border-white' : 'border-transparent',
									thread.SK !== renameThreadId && 'hover:bg-white active:border-navy-100 dark:hover:bg-navy-75 active:dark:border-white',
									'group flex flex-shrink-0 items-center justify-between rounded-[6px] border',
								)}
							>
								{thread.SK === renameThreadId ? (
									<>
										<input
											type="text"
											value={renameThreadValue ?? ''}
											onChange={(event) => {
												setRenameThreadValue(event.target.value)
											}}
											onKeyDown={(event) => {
												if (event.key === 'Enter') renameThread()
											}}
											className="my-[4px] ml-[4px] overflow-hidden rounded-[4px] border bg-white py-0 pl-[4px] dark:bg-navy-100"
										/>
										<button
											onClick={() => {
												renameThread()
											}}
											className="mx-[10px] flex items-center justify-center rounded-full p-[2px] hover:bg-white active:bg-light-grey-25 dark:hover:bg-navy-75 active:dark:bg-navy-100"
										>
											<CheckIcon className="h-[16px]" />
										</button>
									</>
								) : (
									<>
										<Link
											to={`/${isPlayground ? 'securechat' : `projects/${project?.SK}`}/threads/${thread.SK}`}
											className="flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap py-[8px] pl-[8px] text-left text-sm font-[500] focus:outline-none"
										>
											{thread.thread_name}
										</Link>
										<Menu
											as="div"
											className="invisible group-hover:visible data-[open]:visible"
										>
											{({close}) => (
												<>
													<MenuButton
														className="mx-[4px] flex items-center justify-center rounded-full p-[2px] hover:bg-light-grey-25 active:bg-light-grey-25 dark:hover:bg-navy-100"
														onClick={() => {
															setThreadListScrollHeight(threadListScrollRef.current?.scrollTop ?? 0)
														}}
													>
														<EllipsisVerticalIcon className="h-[20px]" />
													</MenuButton>
													<div className="absolute">
														<MenuItems
															onMouseLeave={() => {
																close()
															}}
															className="relative left-[5px] top-2 z-10 mt-2 w-[140px] rounded-[6px] border bg-white p-1 text-sm shadow-lg ring-black ring-opacity-5 dark:border-none dark:bg-navy-75"
															style={{top: '-' + (threadListScrollHeight + 30).toString() + 'px'}}
														>
															<MenuItem>
																<button
																	className="block w-full rounded-[6px] px-4 py-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
																	onClick={() => {
																		setRenameThreadId(thread.SK)
																	}}
																>
																	Rename
																</button>
															</MenuItem>
															<MenuItem>
																<button
																	onClick={() => {
																		setDeleteThreadId(thread.SK)
																	}}
																	className="block w-full rounded-[6px] px-4 py-2 text-left data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
																>
																	Delete
																</button>
															</MenuItem>
														</MenuItems>
													</div>
												</>
											)}
										</Menu>
									</>
								)}
							</div>
						))}
					</div>
				</div>
				<div className="border-t bg-light-grey-50 px-[12px] py-[16px] dark:bg-navy-100">
					<button
						onClick={showProjectConfig}
						className="flex w-full items-center justify-center gap-x-[8px] rounded-[6px] border border-navy-75 px-[9px] py-[7px] text-sm font-[600] hover:bg-light-grey-25 active:bg-light-grey-25 dark:border-white dark:hover:bg-navy-75 dark:active:bg-navy-100"
					>
						<Cog6ToothIcon className="h-[16px]" />
						<div>{isPlayground ? 'Secure chat' : 'Project'} configuration</div>
					</button>
				</div>
			</div>

			<UniqueNameRequiredModal
				open={uniqueNameRequiredType != null}
				onClose={() => {
					setUniqueNameRequiredType(null)
				}}
				itemType={uniqueNameRequiredType ?? ''}
			/>
		</>
	)
}
