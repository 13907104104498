import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {Spinner} from '~/assets/Spinner.tsx'
import {InfoModalRagRetrievalCount} from '~/components/InfoButtonAndModal.tsx'
import {Select} from '~/components/Select.tsx'
import {type DataSet} from '~/model.ts'
import {useDataSet, useReferenceData, useUpdateDataSet} from '~/services/api.ts'
import {numbersToSelectOptions} from '~/util.ts'

export const DataSetDetails = () => {
	const {dataSetId} = useParams()
	const {data: referenceData} = useReferenceData()
	const {mutate: updateDataSetApi, isPending: updateDataSetIsPending} = useUpdateDataSet()
	const {data: dataSet} = useDataSet(dataSetId)

	const isViewer = dataSet?.role === 'VIEWER'

	const {
		register,
		watch,
		control,
		getValues,
		reset,
		formState: {isValid},
	} = useForm<DataSet>({
		defaultValues: dataSet,
		shouldFocusError: false,
		mode: 'all',
		disabled: isViewer,
	})

	useEffect(() => {
		if (dataSet != null) {
			reset(dataSet)
		}
	}, [dataSet, reset])

	const update = () => {
		console.log('update')
		console.log(getValues())
		updateDataSetApi(getValues())
	}

	return (
		<div className="w-full grow overflow-y-auto px-[84px] py-[24px]">
			<h1 className="font-display text-2xl">Details & configuration</h1>
			<p className="mt-[8px] max-w-content text-[18px] text-dark-grey-100 dark:text-light-grey-50">Customise your data set details and default query configuration.</p>

			<h2 className="mt-[48px] text-lg font-[600] light:text-navy-100">Default query configuration</h2>
			<p className="mt-[8px] max-w-content text-dark-grey-75 dark:text-light-grey-50">
				Query configurations give you control over the depth and relevance of the information retrieved by the AI model.
			</p>

			<form>
				{isViewer ? (
					<>
						<div className="mt-[24px] flex max-w-form-sm items-center justify-between">
							<label className="text-[14px] font-[600] text-dark-grey-100 dark:text-white">RAG retrieval count</label>
							<InfoModalRagRetrievalCount />
						</div>
						<div className="mt-[4px]">{dataSet.query_configuration.size}</div>
					</>
				) : (
					<Select
						options={numbersToSelectOptions(referenceData?.rag_retrieval_count)}
						name="query_configuration.size"
						control={control}
					>
						<div className="mt-[24px] flex max-w-form-sm items-center justify-between">
							<label className="text-[14px] font-[600] text-dark-grey-100 dark:text-white">RAG retrieval count</label>
							<InfoModalRagRetrievalCount />
						</div>
					</Select>
				)}

				<h2 className="mt-[48px] text-lg font-[600] light:text-navy-100">Data set details</h2>

				<label
					htmlFor="owner"
					className="mt-[34px] block text-[14px] font-[600] text-dark-grey-100 dark:text-white"
				>
					Business owner
				</label>
				{!isViewer ? (
					<>
						<input
							className="disabled:opacity-70"
							placeholder="E.g. Zoe Visser"
							{...register('owner')}
							maxLength={30}
						/>
						<div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('owner').length}/30</div>
					</>
				) : (
					<div className="mt-[4px]">{dataSet.owner}</div>
				)}

				<label
					htmlFor="description"
					className="mt-[34px] block text-[14px] font-[600] text-dark-grey-100 dark:text-white"
				>
					Description
				</label>
				{!isViewer ? (
					<>
						<textarea
							className="disabled:opacity-70"
							rows={6}
							{...register('description')}
							maxLength={200}
						/>
						<div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('description').length}/200</div>
					</>
				) : (
					<div className="mt-[4px]">{dataSet.description}</div>
				)}

				{!isViewer && (
					<button
						type="button"
						onClick={update}
						disabled={updateDataSetIsPending || !isValid}
						className="mt-[24px] flex items-center rounded-[4px] bg-light-blue-100 p-[10px] px-[11px] py-[7px] text-navy-100 hover:bg-light-blue-25 active:bg-light-blue-50 disabled:cursor-not-allowed disabled:!bg-light-grey-25 disabled:!text-navy-25 dark:disabled:!bg-navy-75 dark:disabled:!text-white"
					>
						{updateDataSetIsPending ? (
							<>
								<Spinner />
								<span>Updating...</span>
							</>
						) : (
							'Update'
						)}
					</button>
				)}
			</form>
		</div>
	)
}
