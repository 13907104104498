import {clsx} from 'clsx'
import {produce} from 'immer'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Draw} from '~/components/Draw.tsx'
import {DataSet, type DataSource} from '~/model.ts'
import {useUpdateDataSources} from '~/services/api.ts'

interface Props {
	dataSet: DataSet
	dataSources: DataSource[]
	dataSource?: DataSource
	onCancel: () => void
	onApply: () => void
}

export const EditFile = ({dataSet, dataSources, dataSource, onCancel, onApply}: Props) => {
	const {mutate: mutateUpdateDataSources} = useUpdateDataSources()

	const {
		register,
		formState: {isValid, errors},
		watch,
		getValues,
		reset,
	} = useForm<DataSource>({
		defaultValues: dataSource ?? {title: '', source: '', uri: ''},
		shouldFocusError: false,
		mode: 'all',
	})

	useEffect(() => {
		reset(dataSource ?? {title: '', source: '', uri: ''})
	}, [dataSource, reset])

	const updateDataSource = () => {
		const updatedDataSource = getValues()
		const updateIndex = dataSources.findIndex((dataSource) => dataSource.SK === updatedDataSource.SK)
		if (updateIndex === -1) return

		const updatedDataSources = produce(dataSources, (draft) => {
			draft[updateIndex] = updatedDataSource
		})
		mutateUpdateDataSources({dataSources: updatedDataSources, dataSetId: dataSet.SK})
		onApply()
	}

	return (
		<Draw
			open={dataSource != null}
			onCancel={onCancel}
			onApply={updateDataSource}
			applyDisabled={!isValid}
		>
			<div className="overflow-y-auto">
				<div className="max-w-content py-[24px] pl-[120px] text-sm">
					<h2 className="font-display text-2xl">Edit file</h2>
					<label
						htmlFor="title"
						className="mt-[34px] block font-[500]"
					>
						* Title
					</label>
					<input
						{...register('title', {required: true, minLength: 2})}
						className={clsx(errors.title != null && '!focus:ring-light-red-100 !focus:border-light-red-100 !border-light-red-100 !ring-light-red-100', 'mt-[4px] w-full max-w-form-sm')}
						maxLength={250}
						placeholder="Enter title"
					/>
					{errors.title == null && <div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('title').length}/250</div>}
					{errors.title?.type === 'required' && (
						<p
							role="alert"
							className="mt-[4px] text-light-red-100"
						>
							Please enter a title
						</p>
					)}
					{errors.title?.type === 'minLength' && (
						<p
							role="alert"
							className="mt-[4px] text-light-red-100"
						>
							Title must be at least 2 characters long
						</p>
					)}

					<label
						htmlFor="source"
						className="mt-[34px] block text-sm font-[600] light:text-dark-grey-100"
					>
						* Source
					</label>
					<input
						{...register('source', {required: true, minLength: 2})}
						className={clsx(errors.source != null && '!focus:ring-light-red-100 !focus:border-light-red-100 !border-light-red-100 !ring-light-red-100', 'mt-[4px] w-full max-w-form-sm')}
						maxLength={1000}
						placeholder="Enter source"
					/>
					{errors.source == null && <div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('source').length}/1000</div>}
					{errors.source?.type === 'required' && (
						<p
							role="alert"
							className="mt-[4px] text-light-red-100"
						>
							Source is required
						</p>
					)}
					{errors.source?.type === 'minLength' && (
						<p
							role="alert"
							className="mt-[4px] text-light-red-100"
						>
							Must be at least 2 characters
						</p>
					)}
				</div>
			</div>
		</Draw>
	)
}
