import {ArrowRightIcon} from '@heroicons/react/16/solid'
import {useAtom} from 'jotai'
import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoCircleIcon} from '~/assets/InfoCircleIcon.tsx'
import {AttachFiles} from '~/components/AttachFiles.tsx'
import {useReferenceData} from '~/services/api.ts'
import {fileStatesAtom} from '~/services/state.ts'

interface PromptInputProps {
	sendNewMessage: (newMessage: string) => void
	modelName: string
}

export const PromptInput = ({sendNewMessage, modelName}: PromptInputProps) => {
	const [text, setText] = useState('')
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const {data: referenceData} = useReferenceData()
	const [fileStates, setFileStates] = useAtom(fileStatesAtom)
	const {threadId} = useParams()

	const modelHasFileUpload = referenceData?.ai_models.find((model) => model.value === modelName)?.features.file_upload
	const fileStatesArray = Object.values(fileStates)
	const hasFiles = fileStatesArray.length > 0
	const processingFiles = fileStatesArray.some((fileState) => fileState.status === 'processing')

	useEffect(() => {
		if (!modelHasFileUpload) {
			setFileStates({})
		}
	}, [modelHasFileUpload])

	const isPlayground = window.location.pathname.startsWith('/securechat')

	const disableSendButton = text.trim().length === 0 || processingFiles
	const showPromptRequired = hasFiles && !processingFiles && text.trim().length === 0

	useEffect(() => {
		const textArea = textareaRef.current
		const container = containerRef.current
		if (textArea && container) {
			textArea.style.marginBottom = '0px'
			if (isPlayground && modelHasFileUpload) {
				containerRef.current.style.flexDirection = 'column'
			} else {
				containerRef.current.style.flexDirection = 'row'
			}
			textArea.style.height = 'auto' // Reset height to get the correct scrollHeight
			console.log(textArea.scrollHeight, textArea.scrollHeight <= 24)
			if (text === '' || textArea.scrollHeight <= 24) {
				// When there should only be one row
				textArea.style.height = '24px'
			} else {
				container.style.flexDirection = 'column'
				textareaRef.current.style.overflowY = 'auto'
				textArea.style.height = `${Math.min(textArea.scrollHeight, 260)}px`
				textArea.style.marginBottom = '8px'
			}
		}
	}, [text, modelHasFileUpload])

	const resetTextArea = () => {
		setText('')
		if (textareaRef.current && containerRef.current) {
			textareaRef.current.value = ''
			textareaRef.current.style.marginBottom = '0px'
			textareaRef.current.rows = 1
			textareaRef.current.style.height = '24px'
			textareaRef.current.style.overflowY = 'hidden'

			if (isPlayground && modelHasFileUpload) {
				containerRef.current.style.flexDirection = 'column'
			} else {
				containerRef.current.style.flexDirection = 'row'
			}

			// Force a re-render to ensure the textarea is cleared
			setTimeout(() => {
				if (textareaRef.current && containerRef.current) {
					textareaRef.current.value = ''
					textareaRef.current.rows = 1
					textareaRef.current.style.height = '24px'
					textareaRef.current.style.overflowY = 'hidden'
					textareaRef.current.style.marginBottom = '0px'
					textareaRef.current.dispatchEvent(new Event('input', {bubbles: true}))

					if (isPlayground && modelHasFileUpload) {
						containerRef.current.style.flexDirection = 'column'
					} else {
						containerRef.current.style.flexDirection = 'row'
					}
				}
			}, 0)
		}
	}

	useEffect(() => {
		resetTextArea()
	}, [threadId])

	const send = () => {
		if (disableSendButton) return
		sendNewMessage(text.trim())
		resetTextArea()
		textareaRef.current?.focus()
	}

	return (
		<div className="mx-auto w-full max-w-content-sm">
			{showPromptRequired ? (
				<div className="mb-[6px] flex items-center rounded-[2px] bg-light-blue-25 px-[8px] py-[2px] text-sm text-navy-100">
					<InfoCircleIcon className="mr-[4px] size-[16px]" />
					Type a message prior to sending files
				</div>
			) : (
				<div className="mb-[4px] flex items-center justify-end text-sm text-dark-grey-75 dark:text-navy-25">
					Use <div className="mx-[4px] rounded bg-white px-[8px] py-[2px] font-[600] text-dark-grey-100 dark:bg-light-blue-25 dark:text-navy-100">shift + return</div> for a new line
				</div>
			)}
			<div
				ref={containerRef}
				className="flex flex-col overflow-hidden rounded-[6px] border bg-light-grey-50 p-[12px] has-[textarea:focus,#send-button:focus]:border-navy-100 has-[textarea:focus,#send-button:focus]:ring-1 has-[textarea:focus,#send-button:focus]:ring-navy-100 dark:bg-navy-100 dark:has-[textarea:focus,#send-button:focus]:border-yellow-100 dark:has-[textarea:focus,#send-button:focus]:ring-yellow-100"
			>
				<textarea
					ref={textareaRef}
					rows={1}
					className="m-0 block h-full w-full max-w-full grow resize-none overflow-y-auto !border-0 bg-light-grey-50 p-0 !ring-0 placeholder:text-navy-75 dark:bg-navy-100 dark:placeholder:text-light-grey-50"
					value={text}
					placeholder="Ask a question..."
					onChange={(e) => {
						setText(e.target.value)
						// Adjust rows based on content
						e.target.rows = e.target.value.split('\n').length
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter' && !event.shiftKey) send()
					}}
				/>

				<div className="flex items-end justify-end has-[input[type='file']]:justify-between">
					{isPlayground && modelHasFileUpload && <AttachFiles />}
					<button
						id="send-button"
						className="max-h-max shrink-0 rounded-[4px] bg-light-blue-100 p-[4px] text-navy-100 !transition-none hover:bg-light-blue-25 active:bg-light-blue-50 disabled:pointer-events-none disabled:bg-light-grey-25 disabled:text-navy-25 dark:disabled:bg-navy-75 dark:disabled:text-white"
						onClick={send}
						disabled={disableSendButton}
					>
						<ArrowRightIcon
							className="h-[16px] !transition-none"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
		</div>
	)
}
