import axios from 'axios'
import {useState} from 'react'
import {useDataSetCreateS3Urls, useReferenceData} from '~/services/api.ts'

interface UploadResponse {
	files: {
		name: string
		s3_presigned_post: {
			url: string
			fields: Record<string, string>
		}
	}[]
}

export const useFileUpload = (dataSetId?: string) => {
	const [fileExtensionError, setFileExtensionError] = useState(false)
	const [fileSizeError, setFileSizeError] = useState(false)
	const [totalUploadSize, setTotalUploadSize] = useState(0)
	const [fileUploadProgress, setFileUploadProgress] = useState<Record<string, number>>({})
	const [uploadingFiles, setUploadingFiles] = useState<File[]>([])
	const [uploading, setUploading] = useState(false)
	const {data: referenceData} = useReferenceData()

	const {mutateAsync: createS3UrlsApi} = useDataSetCreateS3Urls()

	const uploadFiles = async (files: FileList | null) => {
		setUploadingFiles([])
		if (!files || files.length === 0 || dataSetId == null) return

		const filesAsArray = Array.from(files)

		if (filesAsArray.some((file) => !referenceData?.dataset_file_types.includes(file.name.split('.').pop()?.toLowerCase() ?? ''))) {
			setFileExtensionError(true)
			return
		}

		const totalSize = filesAsArray.reduce((acc, file) => acc + file.size, 0)
		if (totalSize > 524_288_000) {
			// 500MB limit
			setFileSizeError(true)
			return
		}

		setUploading(true)
		setUploadingFiles(filesAsArray)
		setTotalUploadSize(totalSize)
		setFileUploadProgress(
			filesAsArray.reduce<Record<string, number>>((acc, file) => {
				acc[file.name] = 0
				return acc
			}, {}),
		)

		const createS3UrlsResponse: UploadResponse = await createS3UrlsApi({dataSetId, files: filesAsArray.map((file) => file.name)})
		const uploadPromises = filesAsArray.map((file) => {
			const presignedUrl = createS3UrlsResponse.files.find((presignedUrlFile) => presignedUrlFile.name === file.name)?.s3_presigned_post
			if (!presignedUrl) return Promise.resolve()

			const formData = new FormData()
			Object.entries(presignedUrl.fields).forEach(([key, value]) => {
				formData.append(key, value)
			})
			formData.append('file', file)

			return axios.post(presignedUrl.url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					setFileUploadProgress((prevProgress) => ({...prevProgress, [file.name]: progressEvent.loaded}))
				},
			})
		})

		await Promise.all(uploadPromises)

		setUploading(false)
		setTotalUploadSize(0)
		setFileUploadProgress({})
	}

	const clearFileErrors = () => {
		setFileExtensionError(false)
		setFileSizeError(false)
	}

	return {
		fileExtensionError,
		fileSizeError,
		totalUploadSize,
		fileUploadProgress,
		uploadingFiles,
		uploading,
		uploadFiles,
		clearFileErrors,
	}
}
