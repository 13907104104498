import {format} from 'date-fns'
import {SelectOption} from '~/components/Select.tsx'

export function isValidHttpUrl(input: string) {
	let url

	try {
		url = new URL(input)
	} catch {
		return false
	}

	return url.protocol === 'http:' || url.protocol === 'https:'
}

// Convert _ & - to spaces and capitalize the first letter of each word
export const convertToTitleCase = (str?: string) => {
	if (!str) return
	return str
		.replaceAll(/[_-]/g, ' ')
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')
}

export const formatDate = (date?: string) => {
	return format(new Date(date ?? ''), 'LLL d, yyyy')
}

export function capitalizeFirstChar(str?: string) {
	if (!str || str.length === 0) return ''
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export async function fileToText(file: File) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.addEventListener('load', (event) => {
			if (event.target?.result && typeof event.target.result === 'string') {
				resolve(event.target.result.toString())
			} else {
				reject(new Error('Failed to read the file.'))
			}
		})
		reader.addEventListener('error', (event) => {
			console.error(event)
			reject(new Error('Failed to read the file.'))
		})
		reader.readAsText(file)
	})
}

export function convertBytesNumberToString(bytes: number): string {
	if (bytes === 0) return '0 bytes'

	const k = 1024
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	let i = Math.floor(Math.log(bytes) / Math.log(k))
	if (bytes / k ** i >= 100) {
		i = i + 1
	}

	return parseFloat((bytes / k ** i).toFixed(2)).toString() + sizes[i]
}

export function convertBytesSizeStringToNumber(sizeString: string): number {
	const units = ['B', 'KB', 'MB', 'GB', 'TB']
	const match = /^(\d+(?:\.\d+)?)([KMGT]?B)$/i.exec(sizeString)

	if (match && typeof match[1] === 'string' && typeof match[2] === 'string') {
		const value = parseFloat(match[1])
		const unit = match[2].toUpperCase()
		const exponent = units.indexOf(unit)
		return Math.round(value * 1024 ** exponent)
	}

	return 0
}

export function numbersToSelectOptions(numbers?: number[]): SelectOption[] {
	if (!numbers) return []
	return numbers.map((number) => ({label: number.toString(), value: number}))
}

export function decodeJwt(token: string | undefined) {
	const blank = {header: {}, payload: {exp: 0}}
	if (token == undefined) {
		console.error('Token is undefined')
		return blank
	}
	if (token.length === 0) {
		console.error('Token is empty')
		return blank
	}

	// Split the token into three parts
	const parts = token.split('.')

	// Check if the token has the correct format
	if (parts.length !== 3) {
		console.error('Token does not have the correct format')
		return blank
	}

	// Decode the base64url-encoded header and payload
	const header = JSON.parse(atob(parts[0])) as unknown
	const payload = JSON.parse(atob(parts[1])) as {exp: number}

	return {
		header,
		payload,
	}
}

export function humanizeDurationDayOrLess(milliseconds: number): string {
	const abs = Math.abs(milliseconds)
	const ms = abs % 1000
	const seconds = Math.floor(abs / 1000) % 60
	const minutes = Math.floor(abs / 60_000) % 60
	const hours = Math.floor(abs / 3_600_000) % 24

	if (hours > 0) {
		return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`
	} else if (minutes > 0) {
		return seconds > 0 ? `${minutes}m ${seconds}s` : `${minutes}m`
	} else if (seconds > 0) {
		return ms > 0 ? `${seconds}s ${ms}ms` : `${seconds}s`
	} else {
		return `${ms}ms`
	}
}
