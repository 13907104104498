import {useAtom} from 'jotai'
import {atomWithStorage, createJSONStorage} from 'jotai/utils'
import {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {EmptyThreadMessage} from '~/components/EmptyThreadMessage.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {PromptInput} from '~/components/PromptInput.tsx'
import {ThreadMenu} from '~/components/ThreadMenu.tsx'
import {type Project} from '~/model.ts'
import {Messages} from '~/pages/project/Messages.tsx'
import {ProjectConfig} from '~/pages/project/ProjectConfig.tsx'
import {ProjectInfo} from '~/pages/project/ProjectInfo.tsx'
import {ProjectSidebar} from '~/pages/project/ProjectSidebar.tsx'
import {ThreadConfig} from '~/pages/project/ThreadConfig.tsx'
import {useChatCompletion, useDeleteThread, useProject, useThread, useThreads, useUpdateProject} from '~/services/api.ts'

const unsavedThreadConfigsAtom = atomWithStorage<Record<string, Project | undefined>>(
	'unsaved-thread-configs',
	{},
	createJSONStorage(() => sessionStorage),
)

export const ProjectView = ({isPlayground = false}: {isPlayground?: boolean}) => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const {projectId, threadId} = useParams()
	const {data: threads} = useThreads(projectId)

	const {data: project, isLoading: projectIsLoading} = useProject(isPlayground ? 'playground' : projectId)
	const {data: thread, isLoading: threadIsLoading} = useThread(projectId, threadId ?? undefined)

	const {mutateAsync: chatCompletionAsyncApi, isPending: chatCompletionIsPending, isError: chatCompletionIsError} = useChatCompletion(projectId, threadId)
	const {mutate: apiDeleteThread} = useDeleteThread()
	const {mutate: updateProjectApi} = useUpdateProject()

	const [pendingMessage, setPendingMessage] = useState<string | null>(null)
	const [deleteThreadId, setDeleteThreadId] = useState<string | null>(null)
	const [renameThreadId, setRenameThreadId] = useState<string | null>(null)
	const [showProjectConfig, setShowProjectConfig] = useState(false)
	const [showProjectView, setShowProjectView] = useState(false)
	const [editThreadConfig, setEditThreadConfig] = useState<Project | null>(null)
	const [showSources, setShowSources] = useState(false)

	const [unsavedThreadConfigs, setUnsavedThreadConfigs] = useAtom(unsavedThreadConfigsAtom)

	useEffect(() => {
		if (!isPlayground && project != null && threads != null && searchParams.has('latest')) {
			if (threads.length > 0) {
				navigate(`/projects/${projectId}/${threads[0]?.SK}`)
			}
			searchParams.delete('latest')
			setSearchParams(searchParams)
		}
	}, [searchParams, project, setSearchParams, navigate, threads, isPlayground, projectId])

	if (projectIsLoading) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	if (project == null) return null

	const unsavedThreadId = threadId ?? (isPlayground ? 'playground' : 'project-' + project.SK)

	const config = unsavedThreadConfigs[unsavedThreadId] ?? (thread?.ui_messages[thread.ui_messages.length - 1] as Project | undefined) ?? project

	const sendNewMessage = (newMessage: string) => {
		if (newMessage.length === 0) {
			return
		}

		void chatCompletionAsyncApi({message: newMessage, config: config}).then((response) => {
			if (unsavedThreadConfigs[unsavedThreadId] != null) {
				setUnsavedThreadConfigs((setUnsavedThreadConfig) => {
					setUnsavedThreadConfig[unsavedThreadId] = undefined
					return setUnsavedThreadConfig
				})
			}
			if (threadId == null) {
				navigate(isPlayground ? `/securechat/threads/${response.SK}` : `/projects/${projectId}/threads/${response.SK}`)
			}
			setPendingMessage(null)
		})

		setPendingMessage(newMessage)
	}

	const deleteThread = () => {
		if (deleteThreadId != null) {
			apiDeleteThread({threadId: deleteThreadId, projectId: projectId})
			if (threadId === deleteThreadId) {
				navigate(isPlayground ? '/securechat' : `/projects/${projectId}`)
			}
		}
		setDeleteThreadId(null)
	}

	const saveProjectConfig = (project: Project) => {
		updateProjectApi(project)
		setShowProjectConfig(false)
	}

	const saveThreadConfig = (project: Project) => {
		setUnsavedThreadConfigs((unsavedThreadConfig) => ({
			...unsavedThreadConfig,
			[unsavedThreadId]: project,
		}))
		setEditThreadConfig(null)
	}

	return (
		<>
			<div className="flex grow">
				<ProjectSidebar
					isPlayground={isPlayground}
					project={project}
					setDeleteThreadId={setDeleteThreadId}
					showProjectConfig={() => {
						if (project.role === 'VIEWER') {
							setShowProjectView(true)
						} else {
							setShowProjectConfig(true)
						}
					}}
					renameThreadId={renameThreadId}
					setRenameThreadId={setRenameThreadId}
				/>
				<div className="flex grow flex-col justify-between">
					<div className="relative flex-1">
						<ThreadMenu
							isPlayground={isPlayground}
							onShowThreadConfig={() => {
								setEditThreadConfig(unsavedThreadConfigs[unsavedThreadId] ?? (thread?.ui_messages[thread.ui_messages.length - 1] as Project | undefined) ?? project)
							}}
							threadId={threadId ?? undefined}
							onRenameThread={() => {
								setRenameThreadId(threadId ?? null)
							}}
							onDeleteThread={() => {
								setDeleteThreadId(threadId ?? null)
							}}
							showSources={showSources}
							onShowSources={() => {
								setShowSources(!showSources)
							}}
						/>

						{threadId != null && threadIsLoading && (
							<div className="flex items-center justify-center px-3">
								<LoadingAnimation />
							</div>
						)}
						{threadId == null && !chatCompletionIsPending && !chatCompletionIsError && (
							<EmptyThreadMessage
								isPlayground={isPlayground}
								onShowProjectConfig={() => {
									setShowProjectConfig(true)
								}}
								role={project.role}
							/>
						)}

						{(thread != null || pendingMessage != null) && (
							<Messages
								messages={thread?.ui_messages ?? []}
								showSources={showSources}
								pendingMessage={pendingMessage}
								isPlayground={isPlayground}
							/>
						)}
					</div>
					<div className="max-h-max border-t bg-light-grey-25 px-3 py-[24px] dark:bg-navy-150">
						<PromptInput
							sendNewMessage={sendNewMessage}
							modelName={config.llm_configuration.model_name}
						/>
					</div>
				</div>
			</div>

			<DeleteConfirmationModal
				open={deleteThreadId != null}
				itemType="thread"
				onClose={() => {
					setDeleteThreadId(null)
				}}
				onDelete={deleteThread}
			/>

			<ProjectConfig
				open={showProjectConfig}
				isPlayground={isPlayground}
				config={project}
				onCancel={() => {
					setShowProjectConfig(false)
				}}
				onApply={saveProjectConfig}
			/>

			<ProjectInfo
				project={showProjectView ? project : undefined}
				onDone={() => {
					setShowProjectView(false)
				}}
			/>

			<ThreadConfig
				isPlayground={isPlayground}
				config={editThreadConfig ?? undefined}
				onCancel={() => {
					setEditThreadConfig(null)
				}}
				onApply={saveThreadConfig}
			/>
		</>
	)
}
