export function CogIcon() {
	return (
		<svg
			className="h-[24px]"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Cog">
				<path
					id="Icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.787 3.80552C13.3321 1.93201 10.6677 1.93201 10.2128 3.80552C9.91903 5.01578 8.53244 5.59012 7.4689 4.9421C5.82252 3.93894 3.93845 5.82301 4.94161 7.46939C5.58964 8.53293 5.01529 9.91951 3.80503 10.2133C1.93153 10.6682 1.93153 13.3326 3.80503 13.7875C5.01529 14.0813 5.58964 15.4679 4.94161 16.5314C3.93845 18.1778 5.82252 20.0618 7.4689 19.0587C8.53244 18.4107 9.91903 18.985 10.2128 20.1953C10.6677 22.0688 13.3321 22.0688 13.787 20.1953C14.0808 18.985 15.4674 18.4107 16.5309 19.0587C18.1773 20.0618 20.0614 18.1778 19.0582 16.5314C18.4102 15.4679 18.9845 14.0813 20.1948 13.7875C22.0683 13.3326 22.0683 10.6682 20.1948 10.2133C18.9845 9.91951 18.4102 8.53293 19.0582 7.46939C20.0614 5.82301 18.1773 3.93894 16.5309 4.9421C15.4674 5.59012 14.0808 5.01578 13.787 3.80552ZM11.9999 15.6004C13.9881 15.6004 15.5999 13.9886 15.5999 12.0004C15.5999 10.0122 13.9881 8.40039 11.9999 8.40039C10.0117 8.40039 8.3999 10.0122 8.3999 12.0004C8.3999 13.9886 10.0117 15.6004 11.9999 15.6004Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	)
}
