export interface ThreadConfig {
	llm_configuration: LlmConfiguration
	query_configuration?: {
		size: number
	}
	prompt_templates: DefaultPromptTemplates
}

export interface NewProject extends ThreadConfig {
	name: string
	dataset?: string
	description: string
}

export type Role = 'OWNER' | 'CONTRIBUTOR' | 'VIEWER'

export interface Project extends NewProject {
	project_type?: string
	status: string
	SK: string
	PK: string
	modified_datetime: string
	Type: string
	role: Role
}

export interface LlmConfiguration {
	temperature: number
	model_name: string
}

export interface DataSet {
	dataset_type: string
	status: DatasetStatus
	scope: string
	modified_by: string
	modified_datetime: string
	query_configuration: {
		size: number
	}
	name: string
	data_classification: string
	vector_configuration: VectorConfiguration
	comment: string
	SK: string
	owner: string
	description: string
	PK: string
	shared_with: unknown[]
	role: Role
}

interface VectorConfiguration {
	chunk_type: string
	chunk_size?: number
	index_name?: string
}

export const ContinuePollingDatasetStatuses = ['PROCESSING'] as const
export const StopPollingDatasetStatuses = ['CREATED', 'ERROR', 'READY'] as const

// Derive types from the const arrays
export type ContinuePollingDatasetStatus = (typeof ContinuePollingDatasetStatuses)[number]
export type StopPollingDatasetStatus = (typeof StopPollingDatasetStatuses)[number]

// Combined type of all statuses
export type DatasetStatus = ContinuePollingDatasetStatus | StopPollingDatasetStatus

// Type guard function
export function isContinuePollingDatasetStatus(status?: DatasetStatus): status is ContinuePollingDatasetStatus {
	if (status == null) return false
	return ContinuePollingDatasetStatuses.includes(status as ContinuePollingDatasetStatus)
}

export interface S3 {
	bucket: string
	max_size_mb: number
	folder: string
}

export interface Thread {
	SK: string
	thread_name?: string
	thread_modified_datetime?: string
	project: string
	ui_messages: UIMessage[]
	Type?: string
	user?: string
}

export interface UIMessage {
	role: string
	content?: string
	converted_content?: string
	language?: string
	id?: string
	response_element?: string
	tokens?: {
		prompt_tokens: number
		completion_tokens: number
		total_tokens: number
	}
	dataset?: string
	dataset_modified_datetime?: string
	index_build?: number
	prompt_template_id?: string
	rag_search_query?: string
	message_modified_date?: string
	sources?: Source[]
	attachments?: Attachment[]
	llm_configuration: LlmConfiguration
	query_configuration?: {
		size: number
	}
	prompt_ai_to_know?: string
	prompt_templates?: DefaultPromptTemplates
}

export interface DefaultPromptTemplates {
	default: {
		prompt_ai_to_know: string
	}
}

export interface Attachment {
	file_name: string
	file_type: string
	file_size: number
	extracted_content: string
}

export interface Source {
	title: string
	source: string
	uri: string
}

export interface ThreadSummery {
	SK: string
	user: string
	thread_modified_datetime: string
	PK: string
	thread_name: string
}

export interface ReferenceData {
	ai_models_default_value: string
	chunk_size: MinMax
	rag_retrieval_count: number[]
	ragchat_url: string
	scope: NameValue[]
	data_classifications: NameValue[]
	Type: string
	temperature: MinMax
	ai_models: {
		name: string
		features: {
			file_upload: boolean
			vision?: boolean
		}
		context_window: number
		value: string
		vendor: string
	}[]
	dataset_file_types: string
	chunk_type_default_value: string
	rag_retrieval_default: number
	llm_features: {
		file_upload: {
			max_files: number
			max_combined_file_size: string
			max_file_size: string
			convert_file_types: string
			text_file_types: string
		}
	}
	prompt_ai_to_know_placeholder: string
	SK: string
	chunk_type: NameValue[]
	PK: string
	chat_url: string
}

export interface MinMax {
	max: number
	default: number
	min: number
	step?: number
}

export interface NameValue {
	name: string
	description?: string
	value: string
}

export interface S3PresignedUrlsResponse {
	files: S3PresignedUrlFile[]
	expiration_seconds: number
}

export interface S3PresignedUrlFile {
	name: string
	s3_presigned_post: {
		url: string
		fields: {
			key: string
			AWSAccessKeyId: string
			'x-amz-security-token': string
			policy: string
			signature: string
		}
	}
}

export interface UpdateDataSourcesRequest {
	datasources: DataSource[]
}

export interface DataSourcesResponse {
	datasources: DataSource[]
	dataset_status: DatasetStatus
	dataset_comment: string
}

export interface DataSource {
	S3: {
		bucket: string
		key: string
	}
	status: 'READY' | 'PROCESSING' | 'ERROR'
	file_type: string
	uri: string
	source: string
	modified_datetime: string
	Type: string
	file_size: number
	comment: string
	SK: string
	PK: string
	title: string
}

export interface RagSources {
	query: string
	ResponseMetadata: {
		RequestId: string
		HTTPStatusCode: number
		RetryAttempts: number
	}
	retrievalResults: Result[]
}

export interface Result {
	score: number
	location: {
		s3Location: {
			uri: string
		}
		type: string
	}
	content: {
		text: string
	}
}

export interface FileState {
	file: File
	status: 'processing' | 'processed' | 'error'
	attachment?: Attachment
	networkError?: string
}
