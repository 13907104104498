import {format} from 'date-fns'
import {useParams} from 'react-router-dom'
import {Draw} from '~/components/Draw.tsx'
import {type UIMessage} from '~/model.ts'

interface Props {
	message?: UIMessage
	onDone: () => void
}

export const MessageInfo = ({message, onDone}: Props) => {
	const {projectId} = useParams()

	const isPlayground = projectId == null

	return (
		<Draw
			open={message != null}
			onDone={onDone}
		>
			<div className="overflow-y-auto px-[120px] py-[24px] text-sm">
				<div className="max-w-content">
					<h2 className="font-display text-2xl">Message information</h2>
					<p className="mt-[8px] text-[18px] light:text-dark-grey-100">Message information showcases the configuration and token usage of the specific message response from the AI model.</p>
				</div>
				<div className="mt-[24px] grid w-full max-w-content-lg grid-cols-1 gap-[24px] rounded-[8px] border p-[24px] min-[848px]:grid-cols-2 min-[1112px]:grid-cols-3">
					{!isPlayground && (
						<>
							<div>
								<div className="pb-[4px] font-[500] dark:text-white">Data set</div>
								<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.dataset}</div>
							</div>
							<div>
								<div className="pb-[4px] font-[500] dark:text-white">Data set last updated</div>
								<div className="text-dark-grey-100 dark:text-light-grey-50">{format(new Date(message?.dataset_modified_datetime ?? 0), 'LLL d, yyyy. h:mmbbb')}</div>
							</div>
							<div>
								<div className="pb-[4px] font-[500] dark:text-white">Chunk size</div>
								<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.query_configuration?.size}</div>
							</div>
							<div>
								<div className="pb-[4px] font-[500] dark:text-white">Chunk type</div>
								<div className="text-dark-grey-100 dark:text-light-grey-50">token</div>
							</div>
							<div>
								<div className="pb-[4px] font-[500] dark:text-white">RAG retrieval count</div>
								<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.query_configuration?.size}</div>
							</div>
						</>
					)}
					<div>
						<div className="pb-[4px] font-[500] dark:text-white">Large Language Model (LLM)</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.llm_configuration.model_name}</div>
					</div>
					<div>
						<div className="pb-[4px] font-[500] dark:text-white">Temperature</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.llm_configuration.temperature}</div>
					</div>
					<div>
						<div className="pb-[4px] font-[500] dark:text-white">Input token</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.tokens?.prompt_tokens}</div>
					</div>
					<div>
						<div className="pb-[4px] font-[500] dark:text-white">Output token</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.tokens?.completion_tokens}</div>
					</div>
					<div>
						<div className="pb-[4px] font-[500] dark:text-white">Total token</div>
						<div className="text-dark-grey-100 dark:text-light-grey-50">{message?.tokens?.total_tokens}</div>
					</div>
				</div>
				<div className="mt-[48px] max-w-content">
					<h3 className="text-lg font-[500]">Custom instructions</h3>
					<label
						htmlFor="aiToKnow"
						className="mt-[24px] block text-sm font-[600] light:text-dark-grey-100"
					>
						What would you like the AI model to know to provide better responses?
					</label>
					<textarea
						id="aiToKnow"
						disabled
						className="mt-[4px] w-full max-w-content-lg rounded-[6px] opacity-70 light:border-navy-50 dark:opacity-50"
						rows={6}
						value={message?.prompt_templates?.default.prompt_ai_to_know ?? message?.prompt_ai_to_know}
					/>
				</div>
			</div>
		</Draw>
	)
}
