import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {CogIcon} from '~/assets/CogIcon.tsx'
import {Draw} from '~/components/Draw.tsx'
import {InfoModalRagRetrievalCount, InfoModalTemperature} from '~/components/InfoButtonAndModal.tsx'
import {Select} from '~/components/Select.tsx'
import {type Project} from '~/model.ts'
import {useReferenceData} from '~/services/api.ts'
import {numbersToSelectOptions} from '~/util.ts'

interface Props {
	isPlayground: boolean
	config?: Project
	onCancel: () => void
	onApply: (projectConfig: Project) => void
}

export const ThreadConfig = ({isPlayground, config, onCancel, onApply}: Props) => {
	const {
		register,
		reset,
		watch,
		getValues,
		control,
		formState: {isValid},
	} = useForm<Project>({
		shouldFocusError: false,
		defaultValues: config,
		mode: 'all',
	})
	const {data: referenceData} = useReferenceData()

	useEffect(() => {
		reset(config)
	}, [config, reset])

	return (
		<Draw
			open={config != null}
			onCancel={onCancel}
			onApply={() => {
				onApply(getValues())
			}}
			applyDisabled={!isValid}
		>
			<div className="overflow-y-auto">
				<div className="max-w-content py-[24px] pl-[120px]">
					<h2 className="font-display text-2xl">Thread configuration</h2>
					<p className="mt-[8px] text-[18px] light:text-dark-grey-100">
						Customise your thread by adjusting the {!isPlayground && ' query configuration,'} language model (LLM), temperature, and adding custom instructions.
					</p>

					<div className="mt-[48px] flex gap-x-[24px] rounded-[6px] bg-light-grey-25 p-[24px] light:border dark:bg-navy-100">
						<div className="flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-[6px] bg-yellow-100 text-navy-100">
							<CogIcon />
						</div>
						<div className="flex-grow light:text-navy-75">
							<p>
								Thread configuration will apply to new messages within each individual thread. It will not affect previous messages within the thread and it will not override the
								{isPlayground ? ' secure chat' : ' project'} configuration.
							</p>
							<p className="mt-[1em]">
								<strong>NB:</strong> Changes to thread configuration will only be saved once you have sent a new message in the thread.
							</p>
						</div>
					</div>

					<h3 className="mt-[55px] text-lg font-[600] light:text-navy-100">LLM configuration</h3>
					<p className="light:text-dark-grey-100">Select the temperature you would like to use for the{isPlayground ? ' secure chat' : ' project'} thread.</p>

					<div className="mt-[24px] flex w-full max-w-form-sm items-center justify-between">
						<label className="text-sm font-[600] light:text-dark-grey-100">Large Language Model (LLM)</label>
						{/*<InfoModalLlm />*/}
					</div>
					<div className="mt-3 max-w-max rounded-[6px] bg-light-grey-25 px-[17px] py-[9px] text-sm font-[500] text-navy-75 dark:bg-navy-75 dark:text-white">
						{referenceData?.ai_models.find((model) => model.value === config?.llm_configuration.model_name)?.name}
					</div>

					<div className="mt-[34px] flex w-full max-w-form-sm items-center justify-between">
						<label className="text-sm font-[600] light:text-dark-grey-100">Temperature: {watch('llm_configuration.temperature')}</label>
						<InfoModalTemperature />
					</div>
					<input
						type="range"
						min={referenceData?.temperature.min ?? 0}
						max={referenceData?.temperature.max ?? 2}
						step={referenceData?.temperature.step ?? 0.1}
						{...register('llm_configuration.temperature', {valueAsNumber: true})}
					/>

					{!isPlayground && (
						<>
							<h3 className="mt-[48px] text-lg font-[600]">Query configuration</h3>
							<p className="mt-[8px] text-dark-grey-100 dark:text-light-grey-50">Query configurations give you control over the depth and relevance of the information retrieved by the AI model.</p>
							<Select
								options={numbersToSelectOptions(referenceData?.rag_retrieval_count)}
								name="query_configuration.size"
								control={control}
							>
								<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-[500]">
									<label className="text-sm font-[600] light:text-dark-grey-100">RAG retrieval count</label>
									<InfoModalRagRetrievalCount />
								</div>
							</Select>
						</>
					)}

					<h3 className="mt-[48px] text-lg font-[600]">Custom instructions</h3>
					<p className="text-grey-100">Custom instructions let you share anything you'd like the AI model to consider in its response.</p>
					<label
						htmlFor="aiToKnow"
						className="mt-[24px] block text-sm font-[600] light:text-dark-grey-100"
					>
						What would you like the AI model to know to provide better responses?
					</label>
					<textarea
						className=""
						rows={6}
						{...register('prompt_templates.default.prompt_ai_to_know')}
					/>
				</div>
			</div>
		</Draw>
	)
}
