import {Label, Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/24/outline'
import {clsx} from 'clsx'
import {ReactNode, useEffect, useRef} from 'react'
import {type FieldValues, type UseControllerProps, useController} from 'react-hook-form'

export interface SelectOption {
	value: string | number
	label: string
}

interface SelectProps<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
	options: SelectOption[]
	children: ReactNode
	placeholder?: string
	disabled?: boolean
	className?: string
	dividerIndex?: number
}

export function Select<TFieldValues extends FieldValues>({
	options,
	children,
	placeholder = 'Select an option',
	disabled = false,
	className,
	dividerIndex,
	...controllerProps
}: SelectProps<TFieldValues>) {
	const {
		field,
		fieldState: {error},
	} = useController(controllerProps)

	const buttonRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		const button = buttonRef.current
		if (!button) return

		const handleBlur = () => {
			field.onBlur()
		}

		button.addEventListener('blur', handleBlur)
		return () => {
			button.removeEventListener('blur', handleBlur)
		}
	}, [field.onBlur])

	const selectedOption = options.find((option) => option.value === field.value)

	return (
		<Listbox
			as="div"
			{...field}
			defaultValue=""
			disabled={disabled}
			className={className}
		>
			<Label>{children}</Label>
			<div className="group relative mt-[4px] max-w-form-sm">
				<ListboxButton
					ref={buttonRef}
					className={clsx(
						error != null && '!border-light-red-100 focus:!ring-light-red-100',
						'relative mt-[4px] w-full max-w-[384px] rounded-[6px] border border-navy-100 bg-white px-[13px] py-[9px] ring-1 ring-transparent focus:outline-0 focus:ring-navy-100 disabled:cursor-not-allowed disabled:border-transparent disabled:bg-light-grey-25 disabled:!text-navy-25 data-[open]:ring-navy-100 light:text-dark-grey-100 dark:border-light-grey-100 dark:bg-navy-100 dark:placeholder-dark-grey-50 dark:focus:border-yellow-100 dark:focus:ring-yellow-100 dark:data-[open]:border-yellow-100 dark:data-[open]:ring-yellow-100',
					)}
				>
					<span className="flex items-center">{field.value === '' ? <span className="text-dark-grey-75 dark:text-navy-50">{placeholder}</span> : selectedOption ? selectedOption.label : 'N/A'}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ChevronDownIcon
							className="text-dark-grey-100v h-5 w-5 dark:text-light-grey-50"
							aria-hidden="true"
						/>
					</span>
				</ListboxButton>

				<ListboxOptions className="absolute z-10 mt-1 max-h-[600px] w-full overflow-auto rounded-[6px] border-navy-100 bg-white py-1 text-base ring-opacity-5 data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in light:border sm:text-sm dark:bg-navy-75">
					{options.map((option, index) => (
						<div
							key={option.value}
							className={clsx(dividerIndex === index && index > 0 && 'mt-1 border-t border-navy-100 pt-1 dark:border-navy-50', 'px-1')}
						>
							<ListboxOption
								className="group relative cursor-default select-none rounded-[6px] py-2 pl-3 pr-9 data-[active]:bg-light-grey-25 dark:data-[active]:bg-navy-100"
								value={option.value}
							>
								<div className="flex items-center">
									<span className="ml-3 block truncate group-data-[selected]:font-[600]">{option.label}</span>
								</div>
								<span className="absolute inset-y-0 right-0 flex items-center pr-4 text-yellow-100 [.group:not([data-selected])_&]:hidden">
									<CheckIcon
										className="h-5 w-5"
										aria-hidden="true"
									/>
								</span>
							</ListboxOption>
						</div>
					))}
				</ListboxOptions>
			</div>
			{error && (
				<p
					role="alert"
					className="mt-[4px] text-light-red-100"
				>
					{error.message ?? 'Please select an option'}
				</p>
			)}
		</Listbox>
	)
}
