export const PlusIcon = ({className}: {className?: string}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 21"
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z"
			fill="currentColor"
		/>
	</svg>
)
