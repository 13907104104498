import {Dialog, DialogPanel, DialogTitle, Transition, TransitionChild} from '@headlessui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {Fragment, useRef} from 'react'

interface DeleteConfirmationModalProps {
	open: boolean
	itemType: string
	onClose: () => void
	onDelete: () => void
}

export const DeleteConfirmationModal = ({open, itemType, onClose, onDelete}: DeleteConfirmationModalProps) => {
	const cancelButtonRef = useRef(null)

	return (
		<Transition
			show={open}
			as={Fragment}
			appear
		>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={onClose}
			>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<DialogPanel className="relative m-8 w-full max-w-[512px] transform overflow-clip rounded-lg bg-white text-left shadow-xl transition-all dark:bg-navy-100">
								<div className="px-[24px] pb-[16px] pt-[24px] sm:flex sm:items-start">
									<div className="mx-auto flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full bg-light-red-100 sm:mx-0">
										<ExclamationTriangleIcon
											className="h-[24px] text-white"
											aria-hidden="true"
										/>
									</div>
									<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
										<DialogTitle
											as="h3"
											className="text-lg font-[600] light:text-dark-grey-100"
										>
											Are you sure?
										</DialogTitle>
										<div className="mt-2 text-dark-grey-75 dark:text-light-grey-50">
											<p>Are you sure you want to delete this {itemType}?</p>
											<p className="mt-[12px]">This action cannot be undone.</p>
										</div>
									</div>
								</div>
								<div className="bg-light-grey-25 px-[24px] py-[12px] text-sm font-[500] sm:flex sm:flex-row-reverse dark:bg-navy-75">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-[6px] bg-light-red-100 px-3 py-2 text-white shadow-sm hover:bg-light-red-75 active:bg-light-red-50 sm:ml-[12px] sm:w-auto"
										onClick={onDelete}
									>
										Delete {itemType}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-[6px] bg-white px-3 py-2 text-navy-100 hover:bg-light-grey-25 active:bg-light-grey-50 light:border light:border-navy-100 sm:mt-0 sm:w-auto"
										onClick={onClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
