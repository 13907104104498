import {MoonIcon, SunIcon} from '@heroicons/react/24/solid'
import {clsx} from 'clsx'
import {Link, NavLink} from 'react-router-dom'
import {useUserInitials} from '~/services/auth.ts'
import {useTheme} from '~/services/theme.ts'

export const Navbar = () => {
	const userInitials = useUserInitials()

	const {darkMode, toggleTheme} = useTheme()

	return (
		<nav className="flex h-[70px] shrink-0 items-center justify-between border-b pl-[24px] pr-[24px] lg:pr-[120px] dark:border-navy-50 dark:bg-navy-100">
			<div className="flex flex-grow items-center gap-x-[24px] lg:gap-x-[52px]">
				<Link
					to="/securechat"
					className="flex flex-shrink-0 items-center gap-x-[24px] rounded-[6px]"
				>
					<img
						alt="University of Melbourne"
						src="/uom-logo.svg"
						className="hidden h-[54px] dark:block"
					/>
					<img
						alt="University of Melbourne"
						src="/uom-logo-light.svg"
						className="h-[54px] dark:hidden"
					/>
					<img
						alt="Spark"
						src="/spark-logo.svg"
						className="mt-[-6px] hidden h-[40px] dark:block"
					/>
					<img
						alt="Spark"
						src="/spark-logo-light.svg"
						className="mt-[-6px] h-[40px] dark:hidden"
					/>
				</Link>

				<div className="flex space-x-[24px]">
					<NavLink
						to="/securechat"
						className={({isActive}) =>
							clsx(
								isActive ? 'bg-light-grey-25 dark:border-white dark:bg-navy-150' : 'border-transparent',
								'rounded-[6px] border px-[12px] py-[8px] text-[16px] hover:bg-light-grey-50 active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-150',
							)
						}
						aria-current="page"
					>
						Secure chat
					</NavLink>
					<NavLink
						to="/projects"
						className={({isActive}) =>
							clsx(
								isActive ? 'bg-light-grey-25 dark:border-white dark:bg-navy-150' : 'border-transparent',
								'rounded-[6px] border px-[12px] py-[8px] text-[16px] hover:bg-light-grey-50 active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-150',
							)
						}
						aria-current="page"
					>
						Projects
					</NavLink>
					<NavLink
						to="/datasets"
						className={({isActive}) =>
							clsx(
								isActive ? 'bg-light-grey-25 dark:border-white dark:bg-navy-150' : 'border-transparent',
								'rounded-[6px] border px-[12px] py-[8px] text-[16px] hover:bg-light-grey-50 active:bg-light-grey-25 dark:hover:bg-navy-75 dark:active:bg-navy-150',
							)
						}
						aria-current="page"
					>
						Data sets
					</NavLink>
				</div>
			</div>
			<div className="flex items-center gap-x-[8px]">
				<button
					onClick={toggleTheme}
					className="rounded-full border border-navy-25 bg-light-grey-25 p-[10px] hover:bg-light-grey-50 active:bg-light-grey-25 dark:bg-navy-150 dark:hover:bg-navy-75 dark:active:bg-navy-100"
				>
					{darkMode ? <SunIcon className="h-[20px] w-[20px] text-yellow-100" /> : <MoonIcon className="h-[20px] w-[20px]" />}
				</button>
				<Link
					to="/settings"
					className="rounded-full border bg-light-grey-25 p-[10px] text-sm font-[600] hover:bg-light-grey-50 active:bg-light-grey-25 dark:bg-navy-150 dark:hover:bg-navy-75 dark:active:bg-navy-100"
				>
					<div className="flex h-[20px] w-[20px] justify-center">{userInitials ?? ''}</div>
				</Link>
			</div>
		</nav>
	)
}
