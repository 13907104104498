import {useEffect} from 'react'
import {useAuth} from 'react-oidc-context'
import {Outlet} from 'react-router-dom'
import {NetworkErrorHandling} from '~/components/NetworkErrorHandling.tsx'
import {WindowsSizeIndicator} from '~/components/WindowsSizeIndicator.tsx'
import {useDataSets, useProjects, useReferenceData, useThreads} from '~/services/api.ts'
import {getToken} from '~/services/auth.ts'
import {decodeJwt, humanizeDurationDayOrLess} from '~/util.ts'
import {Navbar} from './components/Navbar.tsx'

export const Root = () => {
	const auth = useAuth()

	useEffect(() => {
		if (auth.isAuthenticated && auth.user?.expires_at) {
			console.log('user', humanizeDurationDayOrLess(auth.user.expires_at * 1000 - Date.now()))
		}

		const localStorageToken = getToken()
		if (localStorageToken) {
			const localStorageTokenDecoded = decodeJwt(localStorageToken)
			console.log('token', humanizeDurationDayOrLess(localStorageTokenDecoded.payload.exp * 1000 - Date.now()))
		}
	}, [auth])

	useEffect(() => {
		console.log('auth.events addAccessTokenExpiring setup')
		return auth.events.addAccessTokenExpiring(() => {
			console.log('access token expiring')
			void auth.signinSilent()
		})
	}, [auth.events])

	useEffect(() => {
		console.log('auth.events addAccessTokenExpiring setup')
		return auth.events.addAccessTokenExpired(() => {
			console.log('access token expired ---')
			sessionStorage.setItem('redirect-after-login', window.location.pathname)
			void auth.signinRedirect()
		})
	}, [auth.events])

	useEffect(() => {
		if (!auth.isAuthenticated && !auth.isLoading) {
			sessionStorage.setItem('redirect-after-login', window.location.pathname)
			void auth.signinRedirect()
		}
	}, [auth, auth.isAuthenticated])

	useReferenceData()
	useThreads('playground')
	useProjects()
	useDataSets()

	return (
		<>
			{auth.isAuthenticated && (
				<div className="flex h-full flex-col">
					<Navbar />
					<Outlet />
				</div>
			)}
			<NetworkErrorHandling />
			{import.meta.env.DEV && <WindowsSizeIndicator />}
		</>
	)
}
