import {Outlet, useParams} from 'react-router-dom'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {DataSetsSideBar} from '~/pages/dataSets/DataSetsSideBar.tsx'
import {useDataSet} from '~/services/api.ts'

export const DataSetView = () => {
	const {dataSetId} = useParams()

	const {data: dataSet, isLoading: dataSetIsLoading} = useDataSet(dataSetId)

	if (dataSetIsLoading || dataSet == null) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="flex grow">
				<DataSetsSideBar dataSet={dataSet} />
				<div className="flex grow flex-col">
					<Outlet />
				</div>
			</div>
		</>
	)
}
