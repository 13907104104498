import {Label, Radio, RadioGroup} from '@headlessui/react'
import {clsx} from 'clsx'
import {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {CogIcon} from '~/assets/CogIcon.tsx'
import {Draw} from '~/components/Draw.tsx'
import {InfoModalRagRetrievalCount, InfoModalTemperature} from '~/components/InfoButtonAndModal.tsx'
import {Select} from '~/components/Select.tsx'
import {type Project} from '~/model.ts'
import {useDataSets, useReferenceData} from '~/services/api.ts'
import {numbersToSelectOptions} from '~/util.ts'

interface Props {
	open: boolean
	isPlayground: boolean
	config?: Project
	onCancel: () => void
	onApply: (projectConfig: Project) => void
}

export const ProjectConfig = ({open, isPlayground, config, onCancel, onApply}: Props) => {
	const {data: dataSets} = useDataSets()
	const {data: referenceData} = useReferenceData()

	const {
		register,
		formState: {isValid, errors},
		watch,
		getValues,
		control,
		reset,
	} = useForm<Project>({
		defaultValues: {
			name: '',
			description: '',
			llm_configuration: {
				temperature: referenceData?.temperature.default ?? 0,
				model_name: referenceData?.ai_models_default_value ?? '',
			},
			query_configuration: {
				size: referenceData?.rag_retrieval_default ?? 4,
			},
			prompt_templates: {
				default: {
					prompt_ai_to_know: '',
				},
			},
		},
		shouldFocusError: false,
		mode: 'all',
	})

	useEffect(() => {
		if (config != null) {
			reset(config)
		}
	}, [config, reset])

	return (
		<Draw
			open={open}
			onCancel={onCancel}
			onApply={() => {
				onApply(getValues())
			}}
			applyDisabled={!isValid}
		>
			<div className="overflow-y-auto">
				<div className="max-w-content py-[24px] pl-[120px]">
					<h2 className="font-display text-2xl">{isPlayground ? 'Secure chat' : 'Project'} configuration</h2>
					<p className="mt-[8px] text-[18px] light:text-dark-grey-100">
						{isPlayground
							? 'Customise your secure chat by configuring the language model (LLM), adjusting the temperature, and adding custom instructions.'
							: "Customise your project by adjusting the query configuration, language model (LLM), temperature, and adding custom instructions. These updates will override default project settings for new threads but won't affect previous threads or messages."}
					</p>

					<div className="mt-[48px] flex gap-x-[24px] rounded-[6px] bg-light-grey-25 p-[24px] light:border dark:bg-navy-100">
						<div className="flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-[6px] bg-yellow-100 text-navy-100">
							<CogIcon />
						</div>
						<div className="flex-grow light:text-navy-75">
							{isPlayground
								? 'Secure chat configuration will apply to new threads, however it won’t override the individual threads configurations within secure chat.'
								: 'Project configuration will apply to new threads, however it won’t override the individual threads configurations within the project.'}
						</div>
					</div>

					{!isPlayground && (
						<>
							<label
								htmlFor="projectName"
								className="mt-[34px] block text-sm font-[600] light:text-dark-grey-100"
							>
								* Project name
							</label>
							<input
								defaultValue=""
								{...register('name', {required: true, minLength: 2})}
								className={clsx(errors.name != null && 'border-light-red-100 focus:ring-light-red-100', 'mt-[4px] w-full max-w-form-sm border')}
								maxLength={50}
							/>
							{errors.name == null && <div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('name').length}/50</div>}
							{errors.name?.type === 'required' && (
								<p
									role="alert"
									className="mt-[4px] text-light-red-100"
								>
									Please give the project a name
								</p>
							)}
							{errors.name?.type === 'minLength' && (
								<p
									role="alert"
									className="mt-[4px] text-light-red-100"
								>
									Project name must be at least 2 characters long
								</p>
							)}
							<label
								htmlFor="description"
								className="mt-[34px] block text-sm font-[600] light:text-dark-grey-100"
							>
								Description
							</label>
							<textarea
								className=""
								rows={6}
								{...register('description')}
								maxLength={200}
							/>
							<div className="mt-[4px] text-dark-grey-75 dark:text-light-grey-50">{watch('description').length}/200</div>
						</>
					)}

					<h3 className="mt-[55px] text-lg font-[600] light:text-navy-100">LLM configuration</h3>
					<p className="light:text-dark-grey-100">Select the LLM and temperature you would like to use for the {isPlayground ? 'secure chat' : 'project'} threads.</p>
					<Controller
						name="llm_configuration.model_name"
						control={control}
						render={({field}) => (
							<RadioGroup
								value={field.value}
								onChange={field.onChange}
								className="max-w-max"
							>
								<Label className="mt-[24px] flex w-full items-center justify-between">
									<label className="text-sm font-[600] light:text-dark-grey-100">Large Language Model (LLM)</label>
									{/*<InfoModalLlm />*/}
								</Label>
								<div className="mt-[8px] flex cursor-pointer divide-x divide-dark-grey-100 overflow-clip rounded-[6px] border border-dark-grey-100 dark:divide-light-grey-100 dark:border-light-grey-100">
									{referenceData?.ai_models.map((model) => (
										<Radio
											key={model.value}
											value={model.value}
											className="px-[17px] py-[9px] text-sm font-[500] data-[checked]:bg-navy-100 data-[checked]:text-white dark:bg-navy-100 dark:data-[checked]:bg-white dark:data-[checked]:text-navy-100"
										>
											<Label>{model.name}</Label>
										</Radio>
									))}
								</div>
							</RadioGroup>
						)}
					/>

					<div className="mt-[34px] flex w-full max-w-form-sm items-center justify-between">
						<label className="text-sm font-[600] light:text-dark-grey-100">Temperature: {watch('llm_configuration.temperature')}</label>
						<InfoModalTemperature />
					</div>
					<input
						type="range"
						min={referenceData?.temperature.min ?? 0}
						max={referenceData?.temperature.max ?? 2}
						step={referenceData?.temperature.step ?? 0.1}
						{...register('llm_configuration.temperature', {valueAsNumber: true})}
					/>

					{!isPlayground && (
						<>
							<h3 className="mt-[55px] text-lg font-[600] light:text-navy-100">Data set</h3>
							<div className="mt-3 max-w-max rounded-[6px] bg-light-grey-25 px-[17px] py-[9px] text-sm font-[500] text-navy-50 dark:bg-navy-75 dark:text-white">
								{[dataSets?.personalDataSets, dataSets?.globalDataSets].flat().find((dataSet) => dataSet?.SK != null && dataSet.SK === config?.dataset)?.name}
							</div>

							<h3 className="mt-[55px] text-lg font-[600] light:text-navy-100">Query configuration</h3>
							<p className="light:text-dark-grey-100">Query configurations give you control over the depth and relevance of the information retrieved by the AI model.</p>
							<Select
								options={numbersToSelectOptions(referenceData?.rag_retrieval_count)}
								name="query_configuration.size"
								control={control}
							>
								<div className="mt-[24px] flex max-w-form-sm items-center justify-between font-[500]">
									<label className="text-sm font-[600] light:text-dark-grey-100">RAG retrieval count</label>
									<InfoModalRagRetrievalCount />
								</div>
							</Select>
						</>
					)}

					<h3 className="mt-[55px] text-lg font-[600] light:text-navy-100">Custom instructions</h3>
					<p className="light:text-dark-grey-100">Custom instructions let you share anything you'd like the AI model to consider in its response.</p>
					<label
						htmlFor="aiToKnow"
						className="mt-[24px] block text-sm font-[600] light:text-dark-grey-100"
					>
						What would you like the AI model to know to provide better responses?
					</label>
					<textarea
						className="w-full max-w-content"
						rows={6}
						{...register('prompt_templates.default.prompt_ai_to_know')}
					/>
				</div>
			</div>
		</Draw>
	)
}
