import './index.css'
import {QueryClientProvider} from '@tanstack/react-query'
import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import {AuthProvider} from 'react-oidc-context'
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom'
import {DataSetDetails} from '~/pages/dataSets/DataSetDetails.tsx'
import {DataSetFiles} from '~/pages/dataSets/DataSetFiles.tsx'
import {DataSets} from '~/pages/dataSets/DataSets.tsx'
import {DataSetView} from '~/pages/dataSets/DataSetView.tsx'
import {LoginCallback} from '~/pages/LoginCallback.tsx'
import {MaintenancePage} from '~/pages/MaintenancePage.tsx'
import {Projects} from '~/pages/project/Projects.tsx'
import {ProjectView} from '~/pages/project/ProjectView.tsx'
import {Settings} from '~/pages/Settings.tsx'
import {Root} from '~/Root.tsx'
import {queryClient} from '~/services/api.ts'
import {oidcConfig} from '~/services/auth.ts'
import {DARK_MODE_LOCAL_STORAGE_KEY} from '~/services/theme.ts'

if (window.location.pathname === '/') window.location.href = '/securechat'
if (import.meta.env.VITE_DOWN_FOR_MAINTENANCE === 'true' && localStorage.getItem('bypass-maintenance') !== 'true') window.location.href = '/maintenance'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/securechat/threads?/:threadId?',
				element: <ProjectView isPlayground />,
			},
			{
				path: '/projects',
				element: <Projects />,
			},
			{
				path: '/projects/:projectId/threads?/:threadId?',
				element: <ProjectView />,
			},
			{
				path: '/datasets',
				element: <DataSets />,
			},
			{
				path: '/datasets/:dataSetId',
				element: <DataSetView />,

				children: [
					{
						path: '/datasets/:dataSetId',
						element: <DataSetDetails />,
					},
					{
						path: 'files',
						element: <DataSetFiles />,
						children: [],
					},
				],
			},
			{
				path: '/settings',
				element: <Settings />,
			},
		],
	},
	{
		path: '/login/callback',
		element: <LoginCallback />,
	},
	{
		path: '/maintenance',
		loader: () => {
			console.log('maintenance loader', import.meta.env.VITE_DOWN_FOR_MAINTENANCE !== 'true')
			if (import.meta.env.VITE_DOWN_FOR_MAINTENANCE !== 'true') return redirect('/securechat')
			return null
		},
		element: <MaintenancePage />,
	},
])

// Set dark mode early, so we don't get a flash of light mode

const darkMode = localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY)
if (darkMode === 'true' || (darkMode !== 'false' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
	document.documentElement.classList.add('dark')
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider {...oidcConfig}>
				<RouterProvider router={router} />
			</AuthProvider>
		</QueryClientProvider>
	</StrictMode>,
)
